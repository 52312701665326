export const alizarinCrimson = '#DE2139';
export const actionItemTintColor = '#89C947';
export const altTabDark = '#89C947';
export const altTabLight = '#DBF2C3';
export const antiFlashWhite = '#F2F2F2';
export const background = '#F9F9F9';
export const backgroundAlt = '#FAFAFA';
export const black = '#000';
export const borderColor = '#E3E7E9';
export const borderOutline = '#D8D8D8';
export const brandPrimary = '#0466FF';
export const brandPrimaryDark = '#00243D';
export const brandPrimaryActive = '#0142A5';
export const brandPrimaryHover = '#00CCFF';
export const brandPrimaryHoverTint = '#EBFBFF';
export const brandPrimaryText = brandPrimary;
export const brandSecondary = '#3E8BFE';
export const brandSecondaryActive = '#029AD1';
export const brandSecondaryHover = '#0DB7F5';
export const brandSecondaryText = '#0142A5';
export const campaignTintColor = '#6E27A3';
export const classifyContactTintColor = brandSecondary;
export const connectionStatusText = '#909090';
export const contentCalendarTintColor = '#F89143';
export const darkGrayFontColor = '#767676';
export const destructive = '#D0021B';
export const destructiveActive = '#f70220';
export const destructiveHover = '#e3021e';
export const destructiveHoverLight = '#D0021B1A';
export const emailActivityTintColor = '#C75DA6';
export const error = '#B34755';
export const errorLight = '#FEf3F5';
export const grayIconFill = '#A7ABAD';
export const header = '#858585';
export const hotPink = '#C75DA6';
export const label = '#4A4A4A';
export const inputBorderColor = '#CCCCCC';
export const kitTintColor = '#F89143';
export const mention = '#E8F3F9';
export const mentionDark = '#CAE0EC';
export const nameCircles = '#D3DBDE';
export const navigation = '#A7ABAD';
export const noteTintColor = '#F3CE0D';
export const recentMeetingTintColor = brandPrimaryHover;
export const success = '#89C947';
export const successTint = '#DBF2C3';
export const tagBackgroundColor = '#D7EBF7';
export const titles = '#0D0F11';
export const toggleOff = '#D0021B';
export const toggleOn = '#89C947';
export const urgentDate = '#D0021B';
export const warningDark = '#F89143';
export const warningLight = '#FFECDD';
export const surveysTint = '#F3CE0D';
export const dashboardNavBackground = '#00528C';
export const heartRed = '#FF445B';
export const midGray = '#D8D8D8';
export const warningBackground = '#FFFCFD';
export const white = '#FFF';
export const customAutomationTint = '#BAD899';
export const newLogoBackgroundColor = '#00C2F3';
export const alternateTitleColor = '#00AAE8';
export const simplyNotedBlue = '#0040ac';
export const midGrey = '#8e8e8e';
export const green2 = '#BDE098';
export const green3 = '#375C11';
export const green4 = '#99DB56';
export const yellow2 = '#796707';
export const pink2 = '#FFEBF9';
export const spanishBlue = '#046CB6';
export const oldSilver = '#828487';
export const aceGray = '#C6CCD7';
export const charmGray = '#9198A4';
export const lightGray = '#F7F7F7';
export const shuffleGray = '#E9EBEF';
export const prestoPurple = '#8B3DC6';
