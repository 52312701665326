import { CompanyViewModel, ITextMessage } from '../..';
import { BlogStatus, IBulkEmailMessageCompose } from '../../../viewmodels/AppViewModels';
import { EmailAddress, HolidayId } from './Email';
import { IHousehold, IHouseholdRelationship } from './Household';
import { TelephonyServiceProvider } from './Telephony';
import { UserReference } from './User';
import { AchievementModelTypes, GameModelTypes, LeadRuleType } from './coffee';

export * from './AccountTag';
export * from './Automation';
export * from './Contact';
export * from './ContactSearch';
export * from './Email';
export * from './Household';
export * from './TagAlert';
export * from './Telephony';
export * from './Template';
export * from './User';
export * from './blogs';
export * from './coffee';
export * from './requests';
export * from './texting';

export type IDictionary<TValue = any> = Record<string, TValue>;

export enum AutomationTriggerType {
	NewClient = 'NewClientAutomationTrigger',
	NewLead = 'NewLeadAutomationTrigger',
	ResourceSelector = 'ResourceSelectorAutomationTrigger',
	Tag = 'TagAutomationTrigger',
	Manual = 'ManualAutomationTrigger',
	Texting = 'TextingCampaignAutomationTrigger',
	Meeting = 'AutomationTrigger',
}

export type AutomationStepModelType =
	| 'ActionItemAutomationStep'
	| 'TagAutomationStep'
	| 'EmailAutomationStep'
	| 'RemoveTagAutomationStep'
	| 'AddTagAutomationStep'
	| 'TextingAutomationStep'
	| 'SwitchAutomationStep'
	| 'NoActionAutomationStep'
	| 'HandwrittenCardAutomationStep';

export type ApiModelType =
	| 'Account'
	| 'ActionItem'
	| 'Automation'
	| 'AutomationStepReference'
	| 'AutomationTemplate'
	| 'AutomationTemplateReference'
	| 'AutomationTemplateVersion'
	| 'AutomationTrigger'
	| 'Board'
	| 'Campaign'
	| 'CampaignEmail'
	| 'ClassifyContact'
	| 'Company'
	| 'Contact'
	| 'ContactParticipant'
	| 'CustomLeadParser'
	| 'DateTime'
	| 'DealPipeline'
	| 'EmailActivity'
	| 'EmailFollowUpOptions'
	| 'EmailScanner'
	| 'EventRegistrationSurveyResponse'
	| 'EventSurveyResponse'
	| 'HandwrittenCardTemplate'
	| 'KeepInTouch'
	| 'KeyFact'
	| 'Meeting'
	| 'NewClientAutomationTrigger'
	| 'NewLeadAutomationTrigger'
	| 'Note'
	| 'PhoneNumber'
	| 'Post'
	| 'PostReportView'
	| 'ResourceSelectorAutomationTrigger'
	| 'EventRegistrationSurveyResponseFilterRequest'
	| 'SatisfactionSurveyResponseFilterRequest'
	| 'SatisfactionSurveyResponse'
	| 'SocialProfile'
	| 'SurveyFollowUpOptions'
	| 'SystemJob'
	| 'Tag'
	| 'TagAlert'
	| 'TagAutomationTrigger'
	| 'Template'
	| 'TemplateCard'
	| 'TextingCampaignAutomationTrigger'
	| 'UpcomingAnniversaries'
	| 'UpcomingBirthdays'
	| 'UpcomingKeyFact'
	| 'UpcomingRenewals'
	| 'User'
	| 'BlogReportView'
	| AchievementModelTypes
	| AutomationStepModelType
	| GameModelTypes
	| LeadRuleType
	| SurveyStatsTypes
	| SurveyTypes
	| TimelineEventTypes
	| AIModelTypes;

export interface IBaseApiTypeModel {
	_type?: ApiModelType;
}

export type SurveyTypes = 'Survey' | 'EventRegistrationSurvey' | 'SatisfactionSurvey';
export type SurveyStatsTypes = 'SatisfactionSurveyStats' | 'EventSurveyStats';

export type TimelineEventTypes =
	| 'SentEmailEvent'
	| 'MeetingEvent'
	| 'MeetingScheduledEvent'
	| 'NoteEvent'
	| 'ExternalSentEmailEvent'
	| 'ActionItemEvent'
	| 'PhoneCallEvent'
	| 'TextingEvent'
	| 'AutomationEvent'
	| 'AutomationCompletedEvent'
	| 'ReceivedEmailEvent'
	| 'ViewedEmailEvent'
	| 'RepliedEmailEvent'
	| 'ContactArchivedEvent'
	| 'ConversationThreadEvent'
	| 'SurveyResponseEvent'
	| 'SatisfactionSurveyResponseEvent'
	| 'DealCreatedEvent'
	| 'DealUpdatedEvent'
	| 'PhoneCallCompletedEvent'
	| 'UntrackedPhoneCallEvent'
	| 'SkipLeadEvent'
	| 'FollowUpEvent'
	| 'CancelledFollowUpEvent'
	| 'RescheduledFollowUpEvent'
	| 'ExternalSentEmailEvent'
	| 'HandwrittenCardOrderEvent'
	| 'HtmlNewsletterEvent';

export const AppSupportedTimelineTypes: TimelineEventTypes[] = [
	'ActionItemEvent',
	'ConversationThreadEvent',
	'ExternalSentEmailEvent',
	'HandwrittenCardOrderEvent',
	'MeetingEvent',
	'NoteEvent',
	'PhoneCallEvent',
	'SentEmailEvent',
	'SurveyResponseEvent',
	'HtmlNewsletterEvent',
];

export const ExtendedSupportedTimelineTypes: TimelineEventTypes[] = [
	...AppSupportedTimelineTypes,
	'DealCreatedEvent',
	'DealUpdatedEvent',
	'PhoneCallCompletedEvent',
	'UntrackedPhoneCallEvent',
];

export enum Language {
	Unknown = 'Unknown',
	English = 'English',
	Spanish = 'Spanish',
	Italian = 'Italian',
	French = 'French',
	Japanese = 'Japanese',
	German = 'German',
	Portuguese = 'Portuguese',
	Mandarin = 'Mandarin',
	Arabic = 'Arabic',
	Korean = 'Korean',
	Hindi = 'Hindi',
	Punjabi = 'Punjabi',
	Vietnamese = 'Vietnamese',
}

export enum AIPersonalityType {
	Unspecified = 'Unspecified',
	Informative = 'Informative',
	Formal = 'Formal',
	Informal = 'Informal',
	Optimistic = 'Optimistic',
	Friendly = 'Friendly',
	Curious = 'Curious',
	Assertive = 'Assertive',
	Encouraging = 'Encouraging',
	Humorous = 'Humorous',
}

export enum AIContentLength {
	Unspecified = 'Unspecified',
	Short = 'Short',
	Medium = 'Medium',
	Long = 'Long',
}

export type AIModelTypes = 'EmailTranslation' | 'AIGeneratedContent';

export interface IBaseTranslation extends IBaseApiTypeModel {
	sourceLanguage?: Language;
	targetLanguage: Language;
}

export interface IEmailTranslation extends IBaseTranslation {
	content: IRawRichTextContentState;
	subject?: string;
}

export interface IAIGeneratedContent<T> {
	content: T;
	sessionId?: string;
}

export interface IAIContentGenerationOptions {
	contentLength?: AIContentLength;
	/** Float */
	creativity?: number;
	personality?: AIPersonalityType;
	writingInstructions: string;
}

export interface IAIContentGenerationRequest extends IAIContentGenerationOptions {
	creationContextId?: string;
	content?: IRawRichTextContentState;
}

export interface IEmailContentGenerationRequest extends IAIContentGenerationRequest {
	subject?: string;
	templateId?: string;
}

export interface IBlogContentGenerationRequest extends IAIContentGenerationRequest {
	title?: string;
}

export interface ICreationContext {
	categories?: string[];
	/**
	 * List of Related Resources (Key should be name of Model Class: ApiModelType) Assumes at most one of each type since
	 * the type will be the key (e.g. Automation, ResourceSelectorId, ...)
	 */
	resources?: Record<string, string>;
}

export interface IBaseApiModel extends IBaseApiTypeModel {
	id?: string;
	/** This is truly an optional value. This is not always guaranteed to be there. */
	lastModifiedDate?: string;
}

export interface BaseIdModel extends IBaseApiTypeModel {
	id: string;
}

export interface BaseResourceModel extends BaseIdModel {
	creator?: UserReference;
	creationDate?: string;
	lastModifiedDate?: string;
}

export interface IBasicCredential {
	password?: string;
	username?: string;
}

export enum AuthenticationResult {
	Failure = 'Failure',
	InvalidCredentials = 'InvalidCredentials',
	Challenge = 'Challenge',
	MissingAuthConfig = 'MissingAuthConfig',
	Authenticated = 'Authenticated',
	CantIssueToken = 'CantIssueToken',
	NotRequired = 'NotRequired',
}

export enum AuthenticatorType {
	Unknown = 'Unknown',
	Okta = 'Okta',
	SMS = 'SMS',
}

export interface IAuthenticationRequest {
	email?: string;
	password?: string;
	phoneNumber?: string;
	twoFactorCode?: string;
}

export interface IAuthenticationResponse {
	/** Authenticator that failed */
	authenticator?: AuthenticatorType;
	/** User friendly failure message */
	message?: string;
	result?: AuthenticationResult;
	success?: boolean;
	/** Authentication Token only available when Success == true */
	token?: ICredential;
}

/** AuthenticationToken in API */
export interface ICredential {
	access_token?: string;
	account_id?: string;
	email?: string;
	expires_utc?: number;
	password?: string;
	refresh_token?: string;
	token_type?: string;
	/** Obsolete */
	rememberMe?: boolean;
	user_id?: string;
}

export interface IAddress {
	name?: string;
	address1?: string;
	address2?: string;
	city?: string;
	country?: string;
	postalCode?: string;
	stateProvince?: string;
}

export interface IBoardFeatures {
	opportunitiesEnabled?: boolean;
	donationsEnabled?: boolean;
	policiesEnabled?: boolean;
}

export enum EmailScannerId {
	AutoUnsubscribe = 'AutoUnsubscribe',
	Crexi = 'Crexi',
	Custom = 'Custom',
	CustomBounceV2 = 'CustomBounceV2',
	CustomLeadV2 = 'CustomLeadV2',
	Datalot = 'Datalot',
	EndorsedLocalProviders = 'EndorsedLocalProviders',
	Everquote = 'Everquote',
	FiveStreet = 'FiveStreet',
	FollowUpBoss = 'FollowUpBoss',
	Forge3 = 'Forge3',
	FortyTwoStores = 'FortyTwoStores',
	HomeLight = 'HomeLight',
	HomesConnect = 'HomesConnect',
	IDXBroker = 'IDXBroker',
	IHomefinder = 'IHomefinder',
	IHouseWeb = 'IHouseWeb',
	Invalid = 'Invalid',
	Keap = 'Keap',
	LassoCRM = 'LassoCRM',
	LoopNet = 'LoopNet',
	NOLO = 'NOLO',
	Opcity = 'Opcity',
	Placester = 'Placester',
	RealtorDotCom = 'RealtorDotCom',
	ReferralExchange = 'ReferralExchange',
	SmartVestor = 'SmartVestor',
	Spacio = 'Spacio',
	TrustedChoice = 'TrustedChoice',
	WizardCalls = 'WizardCalls',
	ZBuyer = 'ZBuyer',
	Zillow = 'Zillow',
}

export const EmailScannersBlockedFromConfiguration = new Set([
	EmailScannerId.AutoUnsubscribe,
	EmailScannerId.Custom,
	EmailScannerId.CustomBounceV2,
	EmailScannerId.CustomLeadV2,
]);

export const AllEmailScanners: IEmailScanner[] = [
	{ id: EmailScannerId.AutoUnsubscribe, name: 'Auto Unsubscribe' },
	{ id: EmailScannerId.Crexi, name: 'CREXi', tag: 'CREXi Lead' },
	{ id: EmailScannerId.FiveStreet, name: 'FiveStreet', tag: 'FiveStreet Lead' },
	{
		id: EmailScannerId.FollowUpBoss,
		name: 'FollowUpBoss',
		tag: 'FollowUpBoss Lead',
	},
	{
		id: EmailScannerId.HomesConnect,
		name: 'HomesConnect',
		tag: 'HomesConnect Lead',
	},
	{ id: EmailScannerId.IHouseWeb, name: 'iHouseWeb', tag: 'iHouseWeb Lead' },
	{
		id: EmailScannerId.IHomefinder,
		name: 'iHomefinder',
		tag: 'iHomefinder Lead',
	},
	{ id: EmailScannerId.LoopNet, name: 'LoopNet', tag: 'LoopNet Lead' },
	{ id: EmailScannerId.Opcity, name: 'Opcity', tag: 'Opcity Lead' },
	{
		id: EmailScannerId.RealtorDotCom,
		name: 'Realtor.com',
		tag: 'Realtor.com Lead',
	},
	{
		id: EmailScannerId.ReferralExchange,
		name: 'ReferralExchange',
		tag: 'ReferralExchange Lead',
	},
	{ id: EmailScannerId.Spacio, name: 'Spacio', tag: 'Spacio Lead' },
	{ id: EmailScannerId.ZBuyer, name: 'ZBuyer', tag: 'ZBuyer Lead' },
	{ id: EmailScannerId.Zillow, name: 'Zillow', tag: 'Zillow Lead' },
	{ id: EmailScannerId.LassoCRM, name: 'Lasso', tag: 'Lasso Lead' },
	{ id: EmailScannerId.Keap, name: 'Keap', tag: 'Keap Lead' },
	{
		id: EmailScannerId.FortyTwoStores,
		name: '42 Floors',
		tag: '42 Floors Lead',
	},
	{ id: EmailScannerId.HomeLight, name: 'HomeLight', tag: 'HomeLight Lead' },
	{ id: EmailScannerId.IDXBroker, name: 'IDX Broker', tag: 'IDX Broker Lead' },
	{
		id: EmailScannerId.EndorsedLocalProviders,
		name: 'Dave Ramsey ELP',
		tag: 'Dave Ramsey ELP Lead',
	},
	{
		id: EmailScannerId.TrustedChoice,
		name: 'Trusted Choice',
		tag: 'Trusted Choice Lead',
	},
	{
		id: EmailScannerId.WizardCalls,
		name: 'Wizard Calls',
		tag: 'Wizard Calls Lead',
	},
	{ id: EmailScannerId.Everquote, name: 'Everquote', tag: 'Everquote Lead' },
	{ id: EmailScannerId.Datalot, name: 'Datalot', tag: 'Datalot Lead' },
	{
		id: EmailScannerId.SmartVestor,
		name: 'SmartVestor',
		tag: 'SmartVestor Lead',
	},
	{ id: EmailScannerId.Placester, name: 'Placester', tag: 'Placester Lead' },
	{ id: EmailScannerId.NOLO, name: 'NOLO', tag: 'NOLO Lead' },
	{ id: EmailScannerId.Forge3, name: 'Forge3', tag: 'Forge3 Lead' },
];

export const AllEmailScannerIds = AllEmailScanners.map(x => x.id);

export interface IEmailScanFeatures {
	enabledScanners?: EmailScannerId[];
	saveForAnalysis?: boolean;

	/** Represents the date save for analysis is enabled until */
	saveForAnalysisUntil?: string;
	customLeadParsers?: ICustomLeadParser[];
}

export interface IBaseEmailScanner extends IBaseApiModel {
	isDisabled?: boolean;
	name?: string;
	tag?: string;
	stats?: IEmailScannerStats;
}
export enum EmailScannerType {
	EmailScanner = 'EmailScanner',
	CustomLeadParser = 'CustomLeadParser',
}
export interface IEmailScanner extends IBaseEmailScanner {
	id: EmailScannerId;
}
export interface ICustomLeadParser extends IBaseEmailScanner {
	birthdayField?: string;
	emailField?: string;
	firstNameField?: string;
	keyFactField?: string;
	lastNameField?: string;
	nameField?: string;
	phoneField?: string;
	preferHtml?: boolean;
	senderEmail?: string;
	subject?: string;
	tagField?: string;
	addressLine1Field?: string;
	addressLine2Field?: string;
	stateField?: string;
	cityField?: string;
	postalCodeField?: string;
}

export interface IEmailScannerStats extends IBaseApiModel {
	creationDate: string;
	emailScanner: string;
	failureCount: number;
	lastFailureDate: string;
	lastModifiedDate: string;
	lastSuccessDate: string;
	successCount: number;
}

export enum ActivationStatus {
	ACTIVE = 'ACTIVE',
	CANCELED = 'CANCELED',
	DEACTIVATED = 'DEACTIVATED',
	INIT = 'INIT',
}

export interface IAccount extends IBaseApiModel {
	activationStatus?: ActivationStatus;
	additionalInfo?: IAdditionalAccountInfo;
	allowLevitateImpersonationUntil?: Date;
	cancellationDate?: Date;
	cancellationReason?: string;
	companyName?: string;
	creationDate?: Date;
	emailDomain?: string;
	emailProviderConfigurations?: IEmailProviderConfiguration[];
	features?: IAccountFeatures;
	initialUserToken?: string;
	integrations?: IAccountIntegrations;
	planDetails?: IPlan;
	preferences?: IAccountPreferences;
	requireSecondAuthFactor?: boolean;
	usage?: IAccountUsage;
	mainAccountId?: string;
}

export interface IAccountUsage {
	companyEnhancementsRequested?: number;
	statusColor?: string;
	lastNoteCreationDate?: Date;
	isActive?: boolean;
}

export interface IHandwrittenCardTemplate extends ITemplate {
	image: {
		publicUrl: string;
	};
	imageTypes: Record<string, string>;
	parentTemplateId?: string;
	productId: string;
	thumbnail: {
		publicUrl: string;
	};
}

export enum CardImageType {
	Unknown = 'Unknown',
	Face = 'Face',
	Header = 'Header',
	Footer = 'Footer',
	Thumbnail = 'Thumbnail',
}

export enum Industry {
	Accounting = 'Accounting',
	Architecture = 'Architecture',
	Consulting = 'Consulting',
	Engineering = 'Engineering',
	Financial = 'Financial',
	HomeMaintenance = 'Home Maintenance',
	Insurance = 'Insurance',
	Legal = 'Legal',
	Levitate = 'Levitate',
	Marketing = 'Marketing',
	Miscellaneous = 'Misc',
	Mortgage = 'Mortgage',
	NonProfit = 'Non-Profit',
	Others = '_Others_',
	RealEstate = 'Real Estate',
	Recruiting = 'Recruiting',
}

export const AllIndustries: Industry[] = Object.values(Industry);

export enum Stage {
	Adopt = 'Adopt',
	Implementation = 'Implementation',
	NotOnboarded = 'Not Onboarded',
	Onboarding = 'Onboarding',
	Retention = 'Retention',
	Stalled = 'Stalled',
}

export const AllStages: Stage[] = Object.values(Stage);

export enum BrokerDealer {
	AmeritasInvestmentCompany = 'Ameritas Investment Company',
	AusdalFinancialPartners = 'Ausdal Financial Partners',
	BoltonCapital = 'Bolton Capital',
	BRileyFinancial = 'B. Riley Financial',
	Calton = 'Calton',
	CambridgeInvestmentResearch = 'Cambridge Investment Research',
	CentaurusFinancial = 'Centaurus Financial, Inc.',
	Cetera = 'Cetera',
	CommonwealthFinancialNetwork = 'Commonwealth Financial Network',
	DAISecurities = 'DAI Securities',
	DynamicWealth = 'Dynamic Wealth',
	FirstHeartland = 'First Heartland',
	GeneosWealthManagement = 'Geneos Wealth Management',
	GrovePoint = 'Grove Point',
	GWNSecurities = 'GWN Securities',
	HarbourInvestments = 'Harbour Investments, Inc.',
	JWCole = 'JW Cole',
	Kestra = 'Kestra',
	LincolnInvestments = 'Lincoln Investments',
	MutualAdvisors = 'Mutual Advisors',
	MutualGroup = 'Mutual Group',
	NEXTFinancial = 'NEXT Financial',
	PackerlandBrokerageServices = 'Packerland Brokerage Services, Inc.',
	ParklandSecurities = 'Parkland Securities',
	PeachCap = 'Peach Cap',
	Pensionmark = 'Pensionmark',
	SigmaFinancial = 'Sigma Financial',
	SpireSecurities = 'Spire Securities',
	TFSecurities = 'TF Securities',
	TransAmerica = 'TransAmerica',
	UnitedPlanners = 'United Planners',
	ValmarkFinancialGroup = 'Valmark Financial Group',
	ValuesFirstAdvisors = 'Values First Advisors, Inc. (VFA)',
	VFSSecurities = 'VFS Securities',
}

export const AllBrokerDealers: BrokerDealer[] = Object.values(BrokerDealer);

export enum SystemOfRecord {
	AccuAuto = 'Accu Auto',
	Act = 'Act!',
	AdvisorConnect = 'Advisor Connect',
	AdvisorsAssistant = 'Advisors Assistant',
	AdvizorStream = 'Advizor Stream',
	Advyzon = 'Advyzon',
	AgencyAdvantage = 'Agency Advantage',
	AgencyBloc = 'AgencyBloc',
	AgencyBuzz = 'Agency Buzz',
	AgencyElephant = 'Agency Elephant',
	AgencyMatrix = 'Agency Matrix',
	AgencyRevolution = 'Agency Revolution',
	AgencyRoot = 'Agency Root',
	AgencySoftware = 'Agency Software',
	AgencyZoom = 'AgencyZoom',
	AMS360 = 'AMS 360',
	AMSoft = 'AMSoft',
	AppFolio = 'AppFolio',
	AppliedDoris = 'Applied Doris',
	AppliedEpic = 'Applied Epic',
	AppliedTAM = 'Applied TAM',
	Auxilia = 'Auxilia',
	Blackbaud = 'Blackbaud',
	BlackDiamond = 'Black Diamond',
	Bloomerang = 'Bloomerang',
	BombBomb = 'BombBomb',
	Bonzo = 'Bonzo',
	BoomTown = 'BoomTown',
	Broadridge = 'Broadridge',
	Buffini = 'Buffini',
	Buildium = 'Buildium',
	BusinessBuilder = 'Business Builder',
	CalyxPoint = 'Calyx Point',
	CasePeer = 'CASEPeer',
	Chime = 'Chime',
	Clientlook = 'Clientlook',
	Clio = 'Clio',
	Compass = 'Compass',
	ConstantContact = 'Constant Contact',
	Contactually = 'Contactually',
	Copper = 'Copper',
	Donorbox = 'Donorbox',
	DonorPerfect = 'DonorPerfect',
	DonorSnap = 'DonorSnap',
	Donorview = 'Donorview',
	Drake = 'Drake',
	Drip = 'Drip',
	Ease = 'Ease',
	EasyAgent = 'Easy Agent',
	Emma = 'Emma',
	Excel = 'Excel',
	EZAgent = 'EZAgent',
	EZLynx = 'EZLynx',
	Flipcause = 'Flipcause',
	FMGSuite = 'FMG Suite',
	FollowUpBoss = 'Follow Up Boss',
	ForAGoodCause = '4 a Good Cause',
	GenesisNow = 'GenesisNow',
	Giveffect = 'giveffect',
	GivePulse = 'GivePulse',
	GiveSmart = 'GiveSmart',
	GoHighLevel = 'GoHighLevel',
	GoldMine = 'GoldMine',
	Gorilla5 = 'Gorilla 5',
	HarnessGiving = 'Harness Giving',
	Hawksoft = 'Hawksoft',
	HigherLogic = 'Higher Logic',
	Homebot = 'Homebot',
	HouseCallPro = 'HouseCall Pro',
	Hubspot = 'Hubspot',
	IMS = 'IMS',
	InfusionSoft = 'InfusionSoft',
	Insightly = 'Insightly',
	InStar = 'InStar',
	InsuranceNow = 'InsuranceNow',
	Jungo = 'Jungo',
	Junxure = 'Junxure',
	Keap = 'Keap',
	Keela = 'Keela',
	Kindful = 'Kindful',
	KVcore = 'KVcore',
	Lacerte = 'Lacerte',
	Lasso = 'Lasso',
	LeadSimple = 'LeadSimple',
	LessAnnoyingCRM = 'Less Annoying CRM',
	LionDesk = 'LionDesk',
	LittleDog = 'Little Dog',
	LittleGreenLight = 'Little Green Light',
	Mailchimp = 'Mailchimp',
	Mailmunch = 'Mailmunch',
	Maximizer = 'Maximizer',
	MedicarePro = 'Medicare Pro',
	MemberClicks = 'Member Clicks',
	MicrosoftDynamics = 'Microsoft Dynamics',
	MyCase = 'MyCase',
	NasaEclipse = 'Nasa Eclipse',
	NeonOne = 'Neon One',
	Needles = 'Needles',
	NetworkForGood = 'Network for Good',
	Newton = 'Newton',
	Nexsure = 'Nexsure',
	Novi = 'Novi',
	NowCerts = 'NowCerts',
	OutboundEngine = 'Outbound Engine',
	Outreach = 'Outreach',
	PartnerXE = 'Partner XE',
	Pathway = 'Pathway',
	Pipedrive = 'Pipedrive',
	Podium = 'Podium',
	PracticeCS = 'PracticeCS',
	PracticePanther = 'Practice Panther',
	ProSeries = 'ProSeries',
	Pulse = 'Pulse',
	Qgiv = 'Qgiv',
	QQCatalyst = 'QQCatalyst',
	QQEvolution = 'QQEvolution',
	Quickbooks = 'Quickbooks',
	Quomation = 'Quomation',
	Radial = 'Radial',
	RealGeeks = 'RealGeeks',
	Realvolve = 'Realvolve',
	RedFlag = 'RedFlag',
	Redtail = 'Redtail',
	RocketMatter = 'Rocket Matter',
	RocketReferrals = 'Rocket Referrals',
	Salesforce = 'Salesforce',
	Salsa = 'Salsa',
	ServiceTitan = 'Service Titan',
	Shape = 'Shape',
	ShelbySystems = 'Shelby Systems',
	SpecialAgent = 'Special Agent',
	SureFire = 'SureFire',
	Tamarac = 'Tamarac',
	TaxDome = 'TaxDome',
	TCSSoftware = 'TCS Software',
	Thrive = 'Thrive',
	Timeslips = 'Timeslips',
	TopProducer = 'Top Producer',
	UltraTax = 'UltraTax',
	Vertafore = 'Vertafore',
	Virtuous = 'Virtuous',
	Wealthbox = 'Wealthbox',
	Xanatek = 'Xanatek',
	ZAP = 'ZAP',
	Zoho = 'Zoho',
	Zywave = 'Zywave',
}

export const AllSystemsOfRecord: SystemOfRecord[] = Object.values(SystemOfRecord);

export interface IInitiateSchedulerResponse {
	scheduledMeetingId: string;
	/** Full URL to launch to */
	url: string;
}

export interface IAdditionalAccountInfo {
	accountFlagged?: boolean;
	accountScore?: number;
	brokerDealer?: string;
	csmNoteId?: string;
	customerSuccessId?: string;
	customerSuccessName?: string;
	implementationMeeting?: Date | string;
	industry?: Industry;
	lastCsmMeeting?: Date;
	mainContactUserId?: string;
	nextMeeting?: Date;
	renewalPdfSent?: Date;
	renewalProbability?: number;
	renewalDiscountPercentage?: number;
	willRenewPrediction?: string;
	salesNoteId?: string;
	salesRepId?: string;
	salesRepName?: string;
	shareFileFolderLink?: string;
	source?: string;
	stage?: string;
	stateProvince?: string;
	subIndsutry?: string;
	summaryNoteId?: string;
	websitesNoteId?: string;
	systemOfRecord?: string;
	totalCsmMeetings?: number;
	hasCustomContactFields?: boolean;
	websiteStage?: string;
}

export type EmailProviderType =
	| 'Google'
	| 'Gmail'
	| 'Outlook'
	| 'Office365'
	| 'Exchange'
	| 'Imap'
	| 'Misc'
	| 'Yahoo'
	| 'Aol';
export type UserRole = 'superAdmin' | 'admin' | 'user' | 'limitedUser';
export type ResourceVisibility = 'admin' | 'all' | string;

export enum EUserRole {
	Admin = 'admin',
	LimitedUser = 'limitedUser',
	SuperAdmin = 'superAdmin',
	User = 'user',
}

export enum UpcomingKeyDateDisplayScope {
	All = 'All',
	Connections = 'Connections',
	OwnersOnly = 'OwnersOnly',
}

export interface IAccountPreferences {
	handwrittenCardPreferences?: IHandwrittenCardPreferences;
	// emailProvider?: string;
	// relationshipHealthThresholds: IRelationshipHealthThresholds;
	classificationEmailsEnabled?: boolean;
	clickTrackingEnabled?: boolean;
	createContactsFromSentMessages?: boolean;
	csmViewMyCampaignsEnabled?: boolean;
	complianceSettings?: IComplianceSettings;
	blogFeature?: IBlogFeature;
	defaults?: IAccountDefaults;
	deleteRenewalKeyFacts?: boolean;
	digestEmailsEnabled?: boolean;
	disableMobileApps?: boolean;
	disableCalendarContactSync?: boolean;
	disableSentMailOpenTracking?: boolean;
	enableCcInCampaigns?: boolean;
	enableCompanyEnhancement?: boolean;
	ignoreInternalMeetings?: boolean;
	importFromProviderEnabled?: boolean;
	requiredRoleToViewAllOpportunities?: UserRole;
	requiredRoleForFullLevitateUI?: UserRole;
	maximumGroupAutomationContacts?: number;
	maximumGroupSendContacts?: number;
	noteViewerCanEditEnabled?: boolean;
	ownerTagAlertRecipients?: string;
	renewalEmailDaysToLookAhead?: number;
	renewalEmailMonthsToSuppress?: number;
	resourceSelectorSettings?: {
		[key in ResourceSelectorId]: IResourceSelectorSettings;
	};
	saveEmailAsNoteDefault?: boolean;
	sendNewLeadEmail?: boolean;
	sendOnBehalfEnabled?: boolean;
	sendOnBehalfWaitTime?: string;
	showUpcomingBirthdaysTo?: UpcomingKeyDateDisplayScope;
	smartSalutationsEnabled?: boolean;
	storeSubjectInCommunicationHistory?: boolean;
	subverticalSettings?: ISubverticalSettings;
	suppressContactsRemovedFromResourceSends?: boolean;
	unsubscribeTemplateId?: string;
	useUserBccAlwaysEnabled?: boolean;
	hideCompanies?: boolean;
	hideEmailCampaignSignature?: boolean;
	showAllContactsByDefault?: boolean;
	allowChangeUserEmail?: boolean;
	showDuplicateContacts?: boolean;
}

export interface IAccountDefaults {
	visibility?: ResourceVisibility;
	userRole?: UserRole;
}

export interface IEmailCertificateOptions {
	allowAnyCertificate?: boolean;
	allowAnyValidCertificate?: boolean;
}

export interface IExchangeEmailConfiguration extends IEmailCertificateOptions {
	webServicesUrl?: string;
}

export interface IEmailSendLimit {
	intervalInMinutes: number;
	count: number;
}

export interface ISendFrom {
	domain?: string;
}

export interface IBaseEmailProviderConfiguration {
	emailProvider?: EmailProviderType;
	emailSendLimits?: IEmailSendLimit[];
	maxMessageSizeInBytes?: number;
	secondsBetweenSends?: number;
	sendFrom?: ISendFrom;
}

export interface IEmailProviderConfiguration extends IBaseEmailProviderConfiguration {
	domain?: string;
	exchange?: IExchangeEmailConfiguration;
	imap?: IImapEmailConfiguration;
	resolvedConfiguration?: IBaseEmailProviderConfiguration;
}

export interface IPhoneNumberInfo {
	isPortable: boolean;
	portType?: PortType;
	rateCenter?: RateCenter;
	carrier: Carrier;
}

export interface RateCenter {
	name: string;
	state: string;
	city: string;
}

export interface Carrier {
	name: string;
}

export enum PortType {
	Automated = 'Automated',
	Manual = 'Manual',
}

export interface IRateLimit {
	intervalInMinutes: number;
	count: number;
}

export interface IImapEmailConfiguration extends IEmailCertificateOptions {
	imapHost?: string;
	imapPort?: number;
	imapUseSsl?: boolean;
	proxy?: INetworkProxy;
	smtpHost?: string;
	smtpPort?: number;
	smtpUseSsl?: boolean;
}

export enum BillingType {
	Paid = 'paid',
	Trial = 'trial',
	Comp = 'comp',
}

export enum BillingPlan {
	Personal = 0,
	/**
	 * Do not use
	 *
	 * @deprecated
	 */
	Business = 1,
	BusinessSilver = 2,
	BusinessGold = 3,
	ClientHappinessPlatform = 4,
	HappinessPlatformWithAI = 5,
}
export interface ICommunicationPackage {
	enabled?: boolean;
	/**
	 * Controls whether or not the texting and meeting scheduler are paid
	 *
	 * - True = a paid component ($99/month?)
	 * - False = a free component for tracking purposes only
	 * - Null or not included means no component will be added
	 */
	paid?: boolean;
}

export interface IPlan {
	address?: IAddress;
	billingCycle?: number;
	billingReferenceId?: number;
	billingType?: BillingType;
	creditCardInfo?: ICreditCardAttributes;
	discountApplied?: AccountDiscountPercent;
	lastBill?: IBill;
	nextBill?: IBill;
	pendingCancellation?: ICancellationRequest;
	planId?: number | BillingPlan;
	planMRR?: number;
	planName?: string;
	renewalDate?: Date;
	trialExpirationDate?: Date;
	trialExtension?: ITrialExtensionSettings;
	users?: number;
}

export interface IBill {
	amount?: number;
	discount?: number;
	date?: Date;
}

export interface ICancellationRequest {
	createdDate?: Date;
	reason?: string;
}

export enum AccountDiscountPercent {
	Ten = 'Ten',
	Fifteen = 'Fifteen',
	TwentyFive = 'TwentyFive',
}

export enum TextMessageCapability {
	Unknown = 'Unknown',
	Yes = 'Yes',
	No = 'No',
	Maybe = 'Maybe',
	OptOut = 'OptOut',
}

export interface IChargifyOffer {
	id: number;
	name: string;
	offerItems: IOfferItem[];
	offerDiscounts: IOfferDiscount[];
	signupPage: string;
}

export interface IOfferItem {
	componentName: string;
	offerValue: number;
}

export interface IOfferDiscount {
	couponName: string;
}

export enum ContactCapability {
	Any = 'Any',
	ReceiveEmail = 'ReceiveEmail',
	ReceiveText = 'ReceiveText',
	ReceiveCard = 'ReceiveCard',
}

export interface IBillingCouponCode {
	description: string;
	discountValue: number;
	value: string;
}

export interface IPurchaseInfo {
	account?: IAccount;
	billingContactEmail?: string;
	billingContactFirstName?: string;
	billingContactLastName?: string;
	communicationPackage?: ICommunicationPackage;
	couponCode?: string;
	discountWhiteGloveOnboarding?: boolean;
}

export interface ICreditCardAttributes {
	billingAddress?: string;
	billingAddress2?: string;
	billingCity?: string;
	billingCountry?: string;
	billingState?: string;
	billingZip?: string;
	CVV?: string;
	expirationMonth?: number;
	expirationYear?: number;
	firstName?: string;
	fullNumber?: string;
	lastName?: string;
}

export interface IAccountTeam {
	label: string;
	name: string;
	type?: string;
}

export interface IPhoneNumber {
	canSendAutomatedSMS?: TextMessageCapability;
	canSMS?: TextMessageCapability;
	label?: string;
	metadata?: IPhoneNumberMetadata;
	value: string;
}

export interface IPhoneNumberMetadata {
	e164?: string;
	standard?: string;
	countryCode?: number;
	extension?: string;
}

export interface INameValuePair<TValue = string> {
	name?: string;
	value: TValue;
}

export interface IContactConnection extends IBaseApiModel {
	notes?: string;
	user?: IUser;
	userHasKeepInTouch?: boolean;
	connectionTypes?: string[];
}

export type EntityType = 'company' | 'principal';

export enum KeyDateKind {
	Anniversary = 'Anniversary',
	Birthday = 'Birthday',
	FinancialReview = 'FinancialReview',
	Renewal = 'Renewal',
	Unknown = 'Unknown',
	Wedding = 'Wedding',
	Turning65 = 'Turning65',
	Turning72 = 'Turning72',
	Turning73 = 'Turning73',
	TurningXX = 'TurningXX',
	Custom1 = 'Custom1',
	Custom2 = 'Custom2',
	Custom3 = 'Custom3',
	Custom4 = 'Custom4',
	Custom5 = 'Custom5',
}

export interface ITextSpan {
	length: number;
	offset: number;
}

export interface IKeyDate {
	day: number;
	kind?: KeyDateKind;
	month: number;
	span: ITextSpan;
}

export interface IKeyFact extends IBaseApiModel {
	creationDate?: string;
	keyDate?: IKeyDate;
	source?: IKeyFactSource;
	value: string;
}

export interface IKeyFactSource {
	additionalMetadata:
		| IBirthInfoKeyFactMetadata
		| ICustomerReferenceKeyFactMetadata
		| ICustomerReferenceDateKeyFactMetadata
		| IInsuranceKeyFactMetadata
		| IPolicyKeyFactMetaData;
	source: IntegrationSources;
}

export interface IKeyFactMetaData {
	_type: IKeyFactType;
}

export enum IKeyFactType {
	BirthInfoKeyFactMetadata = 'BirthInfoKeyFactMetadata',
	CustomerReferenceDateKeyFactMetadata = 'CustomerReferenceDateKeyFactMetadata',
	CustomerReferenceKeyFactMetadata = 'CustomerReferenceKeyFactMetadata',
	InsuranceKeyFactMetadata = 'InsuranceKeyFactMetadata',
	PolicyKeyFactMetadata = 'PolicyKeyFactMetadata',
}

export interface IBirthInfoKeyFactMetadata extends IKeyFactMetaData {
	birthInfo?: IKeyFactBirthInfo;
}

export interface ICustomerReferenceKeyFactMetadata extends IKeyFactMetaData {
	label: string;
	value: string;
}

export interface ICustomerReferenceDateKeyFactMetadata extends IKeyFactMetaData {
	date: string;
	keyDateKind: string;
	label: string;
}

export interface IInsuranceKeyFactMetadata extends IKeyFactMetaData {
	birthInfo?: IKeyFactBirthInfo;
	carrier: string;
	customerNumber: string;
	lineOfBusiness: string;
	renewalDate: string;
}

export interface IPolicyKeyFactMetaData extends IKeyFactMetaData {
	carrier?: string;
	lineOfBusiness?: string;
	renewalDate?: string;
	policyId?: string;
}

export interface ICustomerReferenceKeyFactMetadata {
	label: string;
	value: string;
}

export interface IKeyFactBirthInfo {
	date: string;
	name: string;
}

// this is similar to ConfigurableIntegrationType but requires different casing returned from API.
export enum IntegrationSources {
	AMS360 = 'AMS360',
	CsvImport = 'CsvImport',
	Eclipse = 'Eclipse',
	HawkSoft = 'HawkSoft',
	Redtail = 'Redtail',
	Clio = 'Clio',
	EpicCsv = 'EpicCsv',
	EzLynxCsv = 'EzLynxCsv',
	QQCatalyst = 'QQCatalyst',
	Wealthbox = 'Wealthbox',
	InternalLevitate = 'InternalLevitate',
	MergeAccounting = 'MergeAccounting',
	MergeCrm = 'MergeCrm',
	GeneralCsv = 'GeneralCsv',
	MyCase = 'MyCase',
	DonorPerfect = 'DonorPerfect',
}

export interface IEntity extends IBaseApiModel {
	address?: IAddress;
	bio?: string;
	companyName?: string;
	keyFacts?: string[];
	keyFactsCollection?: IKeyFact[];
	phoneNumbers?: IPhoneNumber[];
	socialProfiles?: ISocialProfile[];
	tags?: string[];
	tagsCollection?: IAccountTag[];
	timeZone?: string;
	webSite?: string;
}

export interface IPrincipal extends IBaseApiModel {
	firstName?: string;
	lastName?: string;
	primaryEmail?: EmailAddress;
	profilePic?: string;
}

export interface IContactIntegrationData {
	customerId: string;
	externalId: string;
	firstName: string;
	lastName: string;
	provider: string;
	emails: string[];
	tags: string[];
	keyFacts: IKeyFact[];
}

export interface IContact extends IPrincipal, IEntity {
	additionalInfo?: any[];
	company?: ICompany;
	companyId?: string;
	connections?: IContactConnection[];
	creatorId?: string;
	duplicateContacts?: IContact[];
	duplicateContactIds?: string[];
	emailAddresses?: EmailAddress[];
	handle?: string;
	handleReverse?: string;
	household?: IHousehold;
	householdMembers?: IContact[];
	householdRelationship?: IHouseholdRelationship;
	inProgressAutomations?: IInProgressAutomation[];
	integrationData?: IContactIntegrationData[];
	isArchived?: boolean;
	isDecisionMaker?: boolean;
	isInternal?: boolean;
	isRecognizedFirstName?: boolean;
	jobTitle?: string;
	metadata?: IContactMetadata;
	nickName?: string;
	ownerId?: string;
	firstNameForSalutation?: string;
	/** Can be null, e.g. personal account */
	relationshipHealth?: IRelationshipHealth;
	source?: IntegrationSources;
	tagsWithAlerts?: string[];
	userHasKeepInTouch?: boolean;
	visibility?: ResourceVisibility;
	visibilityFriendlyName?: string;
	customProperties?: IDictionary<string>;
	canReceiveEmail?: boolean;
	canReceiveText?: boolean;
	canReceiveCard?: boolean;
}

export interface IAction {
	actor?: IActor;
	date?: Date;
}

export interface IContactMetadata {
	automatedSmsOptOutMetadata?: IUnsubscribeMetadata;
	bounceMetadata?: IBounceMetadata;
	unsubscribeMetadata?: IUnsubscribeMetadata;
}

export interface IUnsubscribeMetadata extends IAction {
	reason?: UnsubscribeReason;
}

export interface IBounceMetadata extends IAction {
	reason?: BounceReason;
}

export enum UnsubscribeReason {
	Unknown = 'Unknown',
	ByUser = 'ByUser',
	ByAI = 'ByAI',
	ByIntegration = 'ByIntegration',
	ByRecipient = 'ByRecipient',
	ByCSVParser = 'ByCSVParser',
}

export enum BounceReason {
	Unknown = 'Unknown',
	ByUser = 'ByUser',
	FailedPreSendValidation = 'FailedPreSendValidation',
	FailedSend = 'FailedSend',
	BounceResponseDetected = 'BounceResponseDetected',
}

export interface ICompany extends IEntity {
	emailDomain?: string;
	emailDomains?: string[];
	logoUrl?: string;
}

export interface IActivityByEntity {
	actionItems?: IResourceActivity;
	companyId?: string;
	emailsSent?: IResourceActivity;
	meetings?: IResourceActivity;
	notes?: IResourceActivity;
	phoneCalls?: IResourceActivity;
	percentChange?: number;
	totalItems?: number;
}

export interface IResourceAggregateActivity {
	currentPeriod?: IActivityByEntity;
	previousPeriod?: IActivityByEntity;
}

export interface ICompanyAggregateActivity extends IResourceAggregateActivity {
	company?: ICompany;
}

export interface IContactAggregateActivity extends IResourceAggregateActivity {
	contact?: IContact;
}

export interface IEmployeeAggregateActivity extends IResourceAggregateActivity {
	employee?: IPrincipal;
}

export interface IResourceActivity {
	count?: number;
	percentChange?: number;
}

export enum ContactOwnerCriteria {
	Default = 0,
	ForUserOnly,
	OnBehalfOfContactOwners,
}

export interface IResourceSelectorSettings {
	_type?: 'ResourceSelectorSettings' | 'CustomResourceSelectorSettings' | 'TurningXXResourceSelectorSettings';
	contactOwnerCriteria?: ContactOwnerCriteria;
	displayName?: string;
	daysToLookAhead?: number;
	daysToSnoozeFor?: number;
	id?: ResourceSelectorId;
	phrases?: string[];
	age?: number;
}
export interface ITermsOfServiceAck {
	accountId?: string;
	emailAddress?: string;
	name?: string;
	ipAddress?: string;
	creationDate?: Date;
	id?: string;
}

export interface IUser extends IPrincipal {
	accountId?: string;
	activationStatus?: ActivationStatus;
	basicCredentialsUsername?: string;
	connectedEmailAddress?: string;
	contactId?: string;
	email?: string;
	emailCalendarTokenExists?: boolean;
	emailProvider?: EmailProviderType;
	emailProviderFeatures?: IEmailProviderFeatures;
	groups?: string[];
	lastTestEmail?: ITestEmailResult;
	mobilePhone?: string;
	newPassword?: string;
	password?: string;
	passwordRecoveryAnswer?: string;
	passwordRecoveryQuestion?: string;
	profilePic?: string;
	reconnectRequired?: string;
	rememberMe?: boolean;
	role?: UserRole;
	socialMediaConnectedAccounts?: ISocialMediaConnection[];
	textAutoReply?: ITextAutoReply;
	token?: string;
	userMilestones?: IUserMilestones;
	userPreferences?: IUserPreferences;
}

export interface ITextAutoReply {
	message?: string;
	enabled: boolean;
}

export interface IUserWithStats extends IUser {
	stats: IUserStats;
}

export interface IActionItemCalendarPreferences {
	calendarEventsAsPublic?: boolean;
	createAsAllDay?: boolean;
	enabled?: boolean;
	groupKeepInTouchesTogether?: boolean;
}

export enum NotifyOf {
	Unknown = 'Unknown',
	ReadEmails = 'ReadEmails',
	TextsReceived = 'TextsReceived',
}

export enum HandwritingStyle {
	Aladdin = 'Aladdin',
	Cinderella = 'Cinderella',
	Donald = 'Donald',
	Nemo = 'Nemo',
	Scar = 'Scar',
	Tarzan = 'Tarzan',
	Woody = 'Woody',
}

export interface IHandwrittenCard extends IBaseApiModel {
	canCancelCard?: boolean;
	cancelledDate?: string;
	canEditCard: boolean;
	completedDate?: string;
	content: IRawRichTextContentState;
	costPerCard?: number;
	creator: IUser;
	errorMessage: string;
	failedCount: number;
	hasResolvedOrders: boolean;
	processedCount: number;
	productId: string;
	recipients: IHandwrittenCardRecipient[];
	recipientsSample?: IHandwrittenCardRecipient[];
	returnContact: IHandwrittenCardReturnContact;
	schedule: IScheduledSend;
	scheduledSendDate?: string;
	sendFrom?: ISendEmailFrom;
	senderUserId?: string;
	sentSuccessfullyCount: number;
	signature: string;
	status: CardStatus;
	style: HandwritingStyle;
	templateId: string;
	templateImage: { publicUrl: string };
	templateName: string;
	totalRecipients: number;
}

export interface IHandwrittenCardReturnContact {
	businessName?: string;
	firstName?: string;
	lastName?: string;
	address?: IAddress;
}

export interface IHandwrittenCardRecipient {
	contactId: string;
	firstName?: string;
	lastName?: string;
	addressName?: string;
	address: IAddress;
	content?: IRawRichTextContentState;
	signature?: string;
	style?: HandwritingStyle;
}

export interface IHandwrittenCardPreferences {
	handwritingStyle?: HandwritingStyle;
	returnContact?: IHandwrittenCardReturnContact;
	signature?: string;
}

export interface IGiftingBalance {
	totalAmountAdded: number;
	availableBalance: number;
	pendingBalance: number;
}

export interface IUserPreferences {
	actionItemCalendarPreferences?: IActionItemCalendarPreferences;
	adwerxBccEmail?: string;
	bccEmail?: string;
	clients?: IClientPreferences;
	createContactsFromSentMessages?: boolean;
	defaultGroup?: ResourceVisibility;
	digestNotificationInterval?: 'Daily' | 'Weekly' | 'Never';
	disableCalendarContactSync?: boolean;
	disableOnboardingGuide?: boolean;
	disableOneWayContactSync?: boolean;
	displayMeetingsWithoutOtherAttendees?: boolean;
	emailConnectionOptOut?: boolean;
	handwrittenCardPreferences?: IHandwrittenCardPreferences;
	keepInTouchCommitmentPreferences?: IKeepInTouchCommitmentPreferences;
	notifyOf?: { [key in NotifyOf]: INotificationMethods };
	optedOutEmailScanners?: EmailScannerId[];
	rollingSendLimit?: number;
	sessionReplayOptOut?: boolean;
	skipSendEmailGuide?: boolean;
	templatePreferences?: ITemplatePreferences;
	timeZone?: string;
	unsubscribeTemplateId?: string;
}

export interface IOutlookClientPreferences {
	trackingPixelPollingEnabled?: boolean;
}
export interface IClientPreferences {
	outlookClient?: IOutlookClientPreferences;
}

export interface ITemplatePreferences {
	birthdayTemplateId?: string;
	anniversaryTemplateId?: string;
	renewalTemplateId?: string;
	reviewTemplateId?: string;
	turning65TemplateId?: string;
	turning72TemplateId?: string;
	turning73TemplateId?: string;
	turningXXTemplateId?: string;
	custom1TemplateId?: string;
	custom2TemplateId?: string;
	custom3TemplateId?: string;
	custom4TemplateId?: string;
	custom5TemplateId?: string;
}

export interface IUserMilestones {
	hasCompletedKeepInTouchWizard?: boolean;
	hasConnectedEmailCalendar?: boolean;
	hasConnectedSocialMedia?: boolean;
	hasCreatedActionItem?: boolean;
	hasCreatedKeepInTouch?: boolean;
	hasCreatedNote?: boolean;
	hasImportedContacts?: boolean;
	hasSignatureTemplate?: boolean;
	hasSentKeepInTouch?: boolean;
	hasSuccessfullySentEmail?: boolean;
	hasUsedEmailAddIn?: boolean;
	hasUsedKeyFacts?: boolean;
	hasUsedMobileApp?: boolean;
	hasUsedTags?: boolean;
	hasUsedWebApp?: boolean;
	hasViewedSendMessageHelp?: boolean;
	hasViewedWebAppTutorial?: boolean;
}

export interface IEmailProviderFeatures {
	calendar?: IProviderCalendarFeatures;
	contact?: IProviderContactFeatures;
	email?: IProviderEmailFeatures;
}

/** Note: Name and email fields will only be used for non-authenticated users (example, via marketing web site) */
export interface ISupportRequest {
	email?: string;
	message: string;
	name?: string;
	subject: string;
}

export interface IUserStats extends IBaseApiModel {
	accountId?: string;
	actionItemsCreated?: number;
	contactConnections: number;
	contactImportDate?: Date;
	contactsCreated?: number;
	contactsImported?: boolean;
	emailCalendarTokenExists?: boolean;
	isActive?: boolean;
	keepInTouchesCreated?: number;
	lastActiveUseDate?: Date;
	lastContactModifiedDate?: Date;
	lastEmailSentDate?: Date;
	lastNoteCreationDate?: Date;
	lastOpportunityModifiedDate?: Date;
	mostRecentNoteCreationDate?: Date;
	notesCreated?: number;
	recentTags?: string[];
	statusColor?: string;
	taggingGamePoints?: number;
	userId?: string;
	webhookLastMessageReceivedOn?: Date;
}

export interface IDashboardItem extends IBaseApiModel {
	body?: string;
	contactId?: string;
	contacts?: IContact[];
	displayIndex?: number;
	hideItem?: boolean;
	imageUrl?: string;
	itemDescription?: string;
	itemSignature?: string;
	itemType?: string;
	title?: string;
	userId?: string;
	validUntil?: string;
	isAllDay?: boolean;
	eventStartTime?: string;
	eventEndTime?: string;
}

export interface IDashboard {
	dashboardItems?: IDashboardItem[];
	onboardingGuide?: IOnboardingGuide;
	pastMeetings?: IMeeting[];
	reminders?: IActionItem[];
	upcomingMeetings?: IMeeting[];
}

export interface ITotalByOwner {
	owner?: IUserReference;
	total?: number;
}

export interface IResourceSelectorDashboardCard {
	qualifier?: string;
	total: number;
	type: ResourceSelectorId;
}

export interface IUpcomingKeyDateDashboardCard extends IResourceSelectorDashboardCard {
	contacts?: IContactWithKeyFact[];
	totalByOwner?: ITotalByOwner[];
}

export interface IOnHoldCampaignsDashboardCard extends IResourceSelectorDashboardCard {
	values?: ICampaign[];
}

export interface IDashboardActionItems extends IResourceSelectorDashboardCard {
	actionItems?: IActionItem[];
}

export interface IDashboardClassifyContacts extends IResourceSelectorDashboardCard {
	contactsToClassify?: IClassifyContact[];
}

export interface IRecentMeetingsDashboardCard extends IResourceSelectorDashboardCard {
	recentMeetings?: IMeeting[];
}

export interface IOnHoldAutomationsDashboardCard extends IResourceSelectorDashboardCard {
	values?: IAutomationOnHold[];
}

export type IRecentlyViewedEmailsDashboardCard = IResourceSelectorDashboardCard;

export interface IContactsDashboardCard extends IResourceSelectorDashboardCard {
	values?: IContact[];
}

export interface ICountByTag {
	count: number;
	tag: string;
}

export interface ITagAlertContactsDashboardCard extends IResourceSelectorDashboardCard {
	countByTag?: ICountByTag[];
}

export interface IDashboardEmails {
	automationsOnHold?: IAutomationOnHold[];
	campaignsOnHold?: ICampaign[];
	communicationStats?: ICommunicationStats;
	custom1?: IUpcomingKeyDateDashboardCard;
	custom2?: IUpcomingKeyDateDashboardCard;
	custom3?: IUpcomingKeyDateDashboardCard;
	custom4?: IUpcomingKeyDateDashboardCard;
	custom5?: IUpcomingKeyDateDashboardCard;
	newClientAutomationsOnHold?: IAutomationOnHold[];
	newLeadAutomationsOnHold?: IAutomationOnHold[];
	satisfactionSurveyStats?: ISatisfactionSurveyCardData;
	socialMedia?: ISocialMediaCard;
	upcoming65thBirthdays?: IUpcomingKeyDateDashboardCard[];
	upcoming72ndBirthdays?: IUpcomingKeyDateDashboardCard[];
	upcoming73rdBirthdays?: IUpcomingKeyDateDashboardCard[];
	upcomingAnniversaries?: IUpcomingKeyDateDashboardCard;
	upcomingBirthdays?: IUpcomingKeyDateDashboardCard;
	upcomingRenewals?: IUpcomingKeyDateDashboardCard[];
	upcomingReviews?: IUpcomingKeyDateDashboardCard[];
	upcomingTurningsXX?: IUpcomingKeyDateDashboardCard[];
}

export type IResourceSelectorContactsRequest = IResourceSelectorRequest<IContactFilterCriteria>;

export interface ISendFromOptions {
	mode: ISendEmailFrom;
	selectedUser?: string;
	connectionType?: string;
}

export interface IResourceSelectorContactsByOwnerRequest extends IResourceSelectorRequest<IContactFilterCriteria> {
	/** List of owners to limit results to When omitted means do not limit by contacts by owner (original behavior) */
	ownerIds?: string[];

	/** When set means we want to start the automation/send email on behalf of the contact owner */
	sendOnBehalf?: boolean;

	sendFromOptions?: ISendFromOptions;
}

export type ClassifiedLinesOfBusiness = Record<string, { label: string; value: string }[]>;

export interface IClassifiedPolicies {
	linesOfBusiness: ClassifiedLinesOfBusiness;
}

export enum ResourceSelectorId {
	Undefined = 'Undefined',
	HappyBirthday = 'HappyBirthday',
	Turning65 = 'Turning65',
	Turning72 = 'Turning72',
	Turning73 = 'Turning73',
	TurningXX = 'TurningXX',
	HouseAnniversaries = 'HouseAnniversaries',
	PolicyRenew = 'PolicyRenew',
	EmailsPendingApprovals = 'EmailsPendingApprovals',
	FinancialReview = 'FinancialReview',
	CategorizeContacts = 'CategorizeContacts',
	AutomationsOnHold = 'AutomationsOnHold',
	CategorizeRecentContacts = 'CategorizeRecentContacts',
	DueActionItems = 'DueActionItems',
	RecentMeetings = 'RecentMeetings',
	RecentlyViewedEmails = 'RecentlyViewedEmails',
	KeepInTouchContacts = 'KeepInTouchContacts',
	TagAlertContacts = 'TagAlertContacts',
	GenericKeyDates = 'GenericKeyDates',
	NewClientAutomationsOnHold = 'NewClientAutomationsOnHold',
	NewLeadAutomationsOnHold = 'NewLeadAutomationsOnHold',
	CommunicationStats = 'CommunicationStats',
	SatisfactionSurvey = 'SatisfactionSurvey',
	SocialMedia = 'SocialMedia',
	Custom1 = 'Custom1',
	Custom2 = 'Custom2',
	Custom3 = 'Custom3',
	Custom4 = 'Custom4',
	Custom5 = 'Custom5',
	GoogleBusiness = 'GoogleBusiness',
}

export interface IResourceSelectorRequest<T> {
	excludeIds?: string[];
	filter?: T;
	groupByHousehold?: boolean;
	includeIds?: string[];
	qualifier?: string;
}

export enum CriteriaOperator {
	And,
	Or,
	Not,
	Gte,
	Lte,
}

export interface IContactFilter {
	criteria?: IContactsFilterRequest[];
	criteriaOperator?: CriteriaOperator;
}

export interface IContactWithKeyFact {
	contact: IProjectedContact;
	keyFact: IKeyFact;
}

export interface IDashboardUpdates {
	actionItemsDue: IDashboardActionItems;
	contactsToClassify: IDashboardClassifyContacts;
	newContactsToClassify: IDashboardClassifyContacts;
	reachOutInfo: IReachOutInfo;
	recentlyViewedEmails: number;
	recentMeetings: IDashboardRecentMeetings;
	genericKeyDates: IUpcomingKeyDates;
}

export interface IDashboardUpdate {
	total: number;
	type: ResourceSelectorId;
}

export interface IUpcomingKeyDates extends IDashboardUpdate {
	contacts: IContactWithKeyFact[];
}

export interface IDashboardRecentMeetings extends IDashboardUpdate {
	recentMeetings: IMeeting[];
}

export interface IOnboardingGuide {
	connectedContacts?: number;
	keepInTouchesCreated?: number;
}

export interface IMeeting extends IBaseApiModel {
	attendees?: IMeetingAttendee[];
	endTime?: string;
	iCalUid?: string;
	isAllDay?: boolean;
	isBusy?: boolean;
	recurringId?: string;
	startTime?: string;
	summary?: string;
}

export interface IMeetingAttendee {
	contact?: IPrincipal;
	optional?: boolean;
	organizer?: boolean;
	resource?: boolean;
}

export type FeatureFlag = 'ContactsUpdate'; // | 'FeatureName'

export type NewFeatures = 'MeetingScheduler';

export enum ConnectionState {
	Unknown = 'Unknown',
	Connected = 'Connected', // the only successful state from bandwidth
	Disconnected = 'Disconnected',
	Failed = 'Failed', // any failure state from bandwidth
	Pending = 'Pending',
}

export enum DialerClientType {
	Bridge = 'Bridge',
	Web = 'Web',
}

export enum PhoneNumberScope {
	User = 'User',
	Account = 'Account',
}

export interface ICallForwardingNumber {
	phoneNumber: IPhoneNumberMetadata;
}

export interface ITelephonyConfiguration extends IBaseApiModel {
	assignedUserIds?: string[];
	assignedUsers?: IUserReference[];
	callForwardingNumber?: ICallForwardingNumber;
	connectionState: ConnectionState;
	creationDate?: string;
	creator?: IUserReference;
	disconnectedDate?: string; // DateTime?
	isUsingTemporarySendingNumber?: boolean;
	number: string;
	orderId: string;
	outboundCallingDisabled: boolean;
	phoneNumber: IPhoneNumberMetadata;
	phoneNumberScope: PhoneNumberScope;
	provider: TelephonyServiceProvider;
	sendingPhoneNumberId?: string;
	supportsGroupTexting: boolean;
}

export interface ICoffeeKeyFieldMappings {
	deals: ICoffeeDealKeyFieldMappings;
}

export interface ICoffeeDealKeyFieldMappings {
	associatedContact: string;
	demoPerformDate: string;
	demoPerformer: string;
	meetingScheduler: string;
	name: string;
}

export interface IUserSession {
	account?: IAccount;
	/** Only available on Coffee accounts */
	coffeeKeyFieldMappings?: ICoffeeKeyFieldMappings;
	featureFlags?: FeatureFlag[];
	isCustomerImpersonation?: boolean;
	newFeatures?: NewFeatures[];
	pendingAccountActions?: IAccountAction[];
	pendingActions?: PendingActions[];
	/** Only available on Coffee accounts */
	telephonyConfiguration?: ITelephonyConfiguration;
	user?: IUser;
	levitateUIStimulant?: LevitateUIStimulant;
}

export interface IFileStorageResourceType {
	BusinessCard: any;
	ContentImage: any;
	ProfilePhoto: any;
	Report: any;
	PdfRenderRequest: any;
	MailAttachment: any;
	ContactFileToImport: any;
	ImageToResize: any;
}

export interface IAttendeeOptions {
	enabled?: boolean;
	requireNameAndEmail?: boolean;
	requirePhoneNumber?: boolean;
	guestLimit?: number;
	maximumCapacity?: number;
	registrationDeadline: Date | string;
}

export interface IEventInformation {
	location: string;
	startTime: Date | string;
	endTime?: Date | string;
	details: string;
	image?: IFileAttachment;
	timeZone?: string;
}

export interface IFileAttachment {
	embedded?: boolean;
	fileName: string;
	fileSize: number;
	id: string;
	mimeType?: string;
	url?: string; // TODO: remove this and break out to [FileAttachmentWithUrl](https://github.com/Real-Magic/Levitate-API/blob/fa3a1f3707c62ee1c7b97bb08c96199908971aab/src/Levitate.Api.Models/RichContent.cs#L41)
}

export enum IAttachmentUploadSource {
	Upload = 'Upload',
	Pixabay = 'Pixabay',
}

export interface IFileAttachmentWithURL extends IFileAttachment {
	storedFile?: { publicUrl?: string };
	url?: string;
	source?: IAttachmentUploadSource;
}

export interface IInProgressFileAttachment extends Omit<IFileAttachment, 'id'> {
	id?: string;
	uploadPromise?: Promise<IOperationResult<IFileAttachment>>;
	uploadTaskId: string;
}

export interface IAttachmentFromExternalImageRequest {
	url: string;
	fileName?: string;
}

export interface IEmailTransaction {
	contact?: IContact;
	contacts?: IContact[];
	openDate?: string;
	repliedDate?: string;
	sentDate?: string;
	status?: EmailTransactionStatus;
}

export enum EmailTransactionStatus {
	Any = '',
	Queued = 'Queued',
	Failed = 'Failed',
	Sent = 'Sent',
	Opened = 'Opened',
	Replied = 'Replied',
	Bounced = 'Bounced',
	FailedNoEmail = 'FailedNoEmail',
	Clicked = 'Clicked',
	MachineOpened = 'MachineOpened',
}

export enum RichContentProperty {
	Undefined,
	CreationDate,
	LastModifiedDate,
	Content,
	DueDate,
	IsCompleted,
	AssignedTo,
	Type,
	OwnedBy,
	All,
}

export enum FollowUpType {
	OriginalContent,
	FromTemplate,
	New,
}

export interface IEmailActivity extends IEmailTransaction, IBaseApiModel {
	sentContent?: IRichContent;
}

export interface IRichContent extends IBaseApiModel {
	attachments?: IFileAttachment[];
	content?: IRawRichTextContentState;
	context?: IRichContentContext;
	creationDate?: string;
	creator?: IUser;
	creatorId?: string;
	lastModifiedDate?: string;
	plainTextContent?: string;
	preview?: string;
	referencedEntities?: IRichContentReferencedEntities;
	visibility?: ResourceVisibility;
}

export type ITimelineEventCreationContext = ICreationContext;

export interface ITimelineEvent extends IBaseApiModel {
	/** E.g. userIds, "admin", "all", etc. */
	accessAcls?: string[];
	content?: IRawRichTextContentState;
	contentId?: string;
	creationContext?: ITimelineEventCreationContext;
	creationDate?: string;
	referencedIdentifiers?: string[];
	replaced?: boolean;
	resourceId?: string;
	resourceType?: string;
	scheduledMeeting?: IScheduledMeeting;
	timestamp?: string;
	title?: string;
}

export interface IHandwrittenCardOrderEvent extends ITimelineEvent {
	cardContent?: string;
	cardSignature?: string;
}

export interface IActionItemEvent extends INoteEvent {
	actionItem?: IActionItem;
}

export interface INoteEvent extends ITimelineEvent {
	note?: INote;
}

export interface IAbstractFollowUpEvent extends ITimelineEvent {
	companyId?: string;
	followUpDate?: string;
	followUpId?: string;
}

export interface IConversationThreadEvent extends ITimelineEvent {
	lastMessages?: ITextMessage[];
}

export interface ISentEmailEvent extends ITimelineEvent {
	openDate?: string;
	note?: INote;
}

export interface IHtmlNewsletterEvent extends ITimelineEvent {
	openDate?: string;
	note?: INote;
}

export interface ISurveyResponseEvent<TResponse extends ISurveyResponse = ISurveyResponse> extends ITimelineEvent {
	response: TResponse;
	surveyName: string;
	resourceType: 'SurveyResponse';
}

export interface ISatisfactionSurveyResponseEvent extends ISurveyResponseEvent<ISatisfactionSurveyResponse> {
	_type: 'SatisfactionSurveyResponseEvent';
}

export interface IKeepInTouchReference extends IBaseApiModel {
	contact?: IContact;
	frequency?: number;
}

export interface IActionItem extends IRichContent {
	assignee?: IUser;
	associatedNote?: INote;
	automationId?: string;
	completionDate?: string;
	dueDate?: string;
	isCompleted?: boolean;
	keepInTouchReference?: IKeepInTouchReference;
}

export interface INote extends IRichContent {
	actionItems?: IActionItem[];
	permissions?: IRichContentPermissions;
}

export interface ISocialProfile extends IBaseApiModel {
	bio?: string;
	followers?: number;
	type?: string;
	typeId?: string;
	url?: string;
	username?: string;
}

export interface IContactHistoryItem extends IBaseApiModel {
	body?: string;
	eventTime?: string;
	header?: string;
	type?: 'email' | 'phonecall' | 'meeting';
}

export interface IContactHistory {
	items?: IContactHistoryItem[];
}

export interface ISystemJobBase extends IBaseApiModel {
	additionalFields?: IDictionary<string>;
	additionalInfo?: string;
	dateCreatedUtc?: Date;
	dateLastUpdatedUtc?: Date;
	jobType?: string;
	percentComplete?: number;
	recordsFailed?: number;
	recordsProcessed?: number;
	recordsSucceeded?: number;
	status?: string;
	totalRecords?: number;
}

export interface ISystemJob extends ISystemJobBase {
	userId?: string;
}

export interface IImportJob extends ISystemJobBase {
	userReference?: IUserReference;
}

export interface IKeepInTouch extends IBaseApiModel {
	accountId?: string;
	userId?: string;
	contactId?: string;
	frequency?: number;
	creationDate?: Date;
	lastInteractionDate?: Date;
	nextInteractionDate?: Date;
}

export interface ISocialProfile extends IBaseApiModel {
	bio?: string;
	followers?: number;
	following?: number;
	type?: string;
	typeId?: string;
	typeName?: string;
	url?: string;
	userName?: string;
}

export interface IResolveEmailContext {
	cc: string[];
	sender: string;
	to: string[];
}

export interface IResolvedEmailContext {
	cc: IContact[];
	sender: IContact;
	to: IContact[];
}

export interface IOperationResultNoValue {
	requestId?: string;
	success?: boolean;
	systemCode?: number;
	systemMessage?: string;
}

export interface IReportCommunicationStats {
	emailsSent: number;
	emailReplies: number;
	emailsFailed: number;
	emailsOpened: number;
	emailsBounced: number;
	textsSent: number;
	textReplies: number;
}

export interface IOperationResult<T> extends IOperationResultNoValue {
	value?: T;
}

export interface IBulkOperationResult<T> extends IOperationResultNoValue {
	succeeded?: T[];
	failed?: IOperationResult<T>[];
}

export interface IGroupCampaignResult extends IOperationResultNoValue {
	draftEmailMessage: IBulkEmailMessageCompose;
}

export interface ISortDecriptor<TSortByType = string> {
	sort?: 'asc' | 'desc';
	sortBy?: TSortByType;
}

export interface IResponseTypeDescriptor {
	responseType?: string | null;
}

export interface ITypeOfFilter {
	typeOf?: string[];
}

export interface IAssignedToFilter {
	assignedTo?: string;
}

export interface IPagedResultFetchContext extends IAssignedToFilter, ISortDecriptor, ITypeOfFilter {
	/** Defaults to 25 on the server */
	pageSize?: number;
	pageToken?: string;
	unreadOnly?: boolean;
}

export interface IPagedCollection<T> {
	pageToken?: string;
	totalCount?: number;
	values: T[];
}

export interface IHandleAutoCompleteResult {
	contact?: IContact;
	company?: ICompany;
}

export interface IFormattedName {
	firstName?: string;
	lastName?: string;
	raw?: string;
}

export interface IRecipient {
	email?: string;
	id?: string;
	name?: IFormattedName;
}

export interface IEventRecipient {
	email?: string;
	id?: string;
	name?: string;
	phone?: string;
}

export interface IEmailPreviewRequest {
	content?: IRawRichTextContentState;
	signatureTemplateId?: string;
}

export interface ICarbonCopyRecipients {
	/** Array of email addresses */
	bcc?: string[];

	/** Array of email addresses */
	cc?: string[];
}

export interface IEmailMessage<TFollowUpOptions extends IFollowUpOptions = IEmailMessageFollowUpOptions> {
	/** Default message content to be sent to all requested contacts. Required. */
	content?: IRawRichTextContentState;
	options?: IEmailMessageComposeOptions<TFollowUpOptions>;

	/** Default signature template to be applied. */
	signatureTemplateId?: string;

	/** Default subject to be sent to all requested contacts. Required. */
	subject?: string;
	templateReference?: ITemplateReference;
}

export const SendEmailFrom = {
	CurrentUser: 'CurrentUser',
	ConnectionType: 'ConnectionType',
	ContactOwner: 'ContactOwner',
	SelectedUser: 'SelectedUser',
} as const;

export type ISendEmailFrom = ObjectValues<typeof SendEmailFrom>;

export interface IFollowUpOptions extends IBaseApiTypeModel {
	excludeContactIds?: string[];
	type?: FollowUpType;
}

export interface IEmailMessageFollowUpOptions extends IFollowUpOptions {
	_type: 'EmailFollowUpOptions';
	bulkEmailMessageId?: string;
	statuses?: EmailTransactionStatus[];
}

export interface ISurveyResponseFilterRequest extends IBaseApiTypeModel {
	endDate?: string;
	startDate?: string;
	surveyId: string;
}

export enum EventRegistrationResponseStatus {
	Attending = 'Attending',
	NotAttending = 'NotAttending',
	NoResponse = 'NoResponse',
}

export interface IEventRegistrationSurveyResponseFilterRequest extends ISurveyResponseFilterRequest {
	status: EventRegistrationResponseStatus[];
	_type: 'EventRegistrationSurveyResponseFilterRequest';
}

export interface ISatisfactionSurveyResponseFilterRequest extends ISurveyResponseFilterRequest {
	ratings?: number[];
	_type: 'SatisfactionSurveyResponseFilterRequest';
}

export interface ISurveyFollowUpOptions<T extends ISurveyResponseFilterRequest = ISurveyResponseFilterRequest>
	extends IFollowUpOptions {
	surveyResponseFilter: T;
}

export interface IEmailMessageComposeOptions<TFollowUpOptions extends IFollowUpOptions = IEmailMessageFollowUpOptions> {
	emailSendLimits?: IEmailSendLimit[];
	followUp?: TFollowUpOptions;
	followUpIfNoResponseInDays?: number;
	keepInTouchFrequency?: number;
	minimumDurationInDays?: number;
	noteVisibility?: ResourceVisibility;
	saveAsNote?: boolean;
	scheduledSend?: IScheduledSend;
	sendEmailFrom?: ISendEmailFrom;
	sendEmailFromUser?: IUser;
	sendEmailFromUserId?: string;
	sendFromConnectionType?: string;
	sendOnBehalfEmailNotification?: boolean;
	sendSingleEmail?: boolean;
	sendWithCompliance?: boolean;
	sendWithComplianceEmail?: string;
}

export interface IEmailMessageComposeContext {
	ref?: IReference;
}

export interface IReference extends IBaseApiModel {
	type: 'ActionItem';
}

export interface IEmailMessageContactsFilterRequest {
	contactFilterRequest?: IContactsFilterRequest;
	/**
	 * Sort order in which contacts will be evaluated. Important if the group exceeds the maximum allowed, currently 500,
	 * ensure the order matches user's expectations.
	 */
	sortAscending?: boolean;
	/**
	 * Property on which the sort is evaluated. Important if the group exceeds the maximum allowed, currently 500, ensure
	 * the order matches user's expectations.
	 */
	sortProperty?: keyof IContact;
	groupByHousehold?: boolean;
	groupByDuplicate?: boolean;
	groupByHouseholdFilter?: IContactsFilterRequest;
}

export interface IEmailMessageComposeContact {
	attachments?: (IFileAttachment | IInProgressFileAttachment)[];
	/** ContactId must be provided. */
	contactId?: string;
	/** Customized message specifically for the contact. */
	content?: IRawRichTextContentState;
	/** Any context that is specific to the contact. i.e an action item that is being resolved. */
	context?: IEmailMessageComposeContext;
	/** Specifies the email address of the contact to use. If not provided, the primary email address will be used. */
	preferredEmailAddress?: string;
	signatureTemplateId?: string;
	/** Customized subject specifically for the contact. */
	subject?: string;
}

export interface IEmailMessageCompose<TFollowUpOptions extends IFollowUpOptions = IEmailMessageFollowUpOptions>
	extends IEmailMessage<TFollowUpOptions> {
	aiReference?: IAIReference;
	cancelledDate?: string;

	carbonCopy?: ICarbonCopyRecipients;
	/** Contacts to exclude from `ContactsFilterRequest` results */
	contactIdsToOmit?: string[];
	/**
	 * Provides supplemental information to `ContactsFilterRequest` or if `ContactsFilterRequest` is not supplied it's the
	 * authority.
	 */
	contacts?: IEmailMessageComposeContact[];
	/**
	 * Filter request that specifies the contacts to be emailed. If not supplied, only contacts defined in `Contacts` will
	 * be notified.
	 */
	contactsFilterRequest?: IEmailMessageContactsFilterRequest;

	templateReference?: ITemplateReference;
}

export interface IBlogCompose {
	content: IRawRichTextContentState;
	/** The title of the blog post. */
	title: string;
}

export interface ICancelAccount {
	reason?: string;
}

export enum RichContentReferenceMethod {
	Implicit = 0,
	Explicit,
	Background,
}

export interface IRichContentEntityReference<T> {
	entity: T;
	method: RichContentReferenceMethod;
}

export interface IRichContentReferencedEntities {
	companies?: IRichContentEntityReference<ICompany>[];
	contacts?: IRichContentEntityReference<IContact>[];
	users?: IRichContentEntityReference<IUser>[];
}

export enum RichContentContextSource {
	Unknown = 0,
	Meeting,
	EmailMessageSend,
	EmailMessageFollowUp,
	PhoneCall,
	TextMessage,
}

export enum CsmMeetingTypes {
	AIContentReview = 'AI Content Review',
	CheckIn = 'Check-in',
	ClientServices = 'Client Services',
	ContentPlanning = 'Content Planning',
	CustomContent = 'Custom Content',
	DataRefresh = 'Data Refresh',
	Onboarding = 'Onboarding',
	RenewalCall = 'Renewal Call',
	SupportCall = 'Support Call',
	TrainingStrategy = 'Training & Strategy',
}

export enum WebsiteMeetingTypes {
	Kickoff = 'Kickoff',
	CheckIn = 'Check-in',
	GoLive = 'Go Live',
}

export interface IRichContentContext {
	name?: string;
	source: RichContentContextSource;
}

export interface IMeetingContext extends IRichContentContext {
	durationInMinutes?: number;
	meetingStatus?: string;
	meetingTags?: string[];
	meetingType?: CsmMeetingTypes | WebsiteMeetingTypes;
	source: RichContentContextSource.Meeting;
}

export interface IEmailMessageSendContext extends IRichContentContext {
	openDate: string;
}

export interface IRawRichTextContentState {
	document?: string;
	documentVersion?: number;
	source?: string;
	sourceFormat?: ContentSourceFormat;
}

export interface IEmbeddedSocialMediaTemplateContent {
	content?: IRawRichTextContentState;
	attachments?: IFileAttachment[];
	citation?: string;
	keywords?: string[];
	summary?: string;
	templateId?: string;
}

export enum TemplateType {
	Signature = 0,
	Email,
	PersonalBusinessUpdate,
	Automation,
	UnsubscribeInstructions,
	HtmlNewsletter,
	UnsubscribeFromNewsletter,
	SocialMediaPost,
	Translation,
	HandwrittenCard,
	Blog,
}

export enum ContentSourceFormat {
	Unknown = 'Unknown',
	UnlayerHtmlNewsletter = 'UnlayerHtmlNewsletter',
}

export enum TemplateScope {
	User = 'User',
	Account = 'Account',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	Industry = 'Industry',
	System = 'System',
}

export interface IAccountAction extends IBaseResourceModel {
	status: AccountActionStatus;
	performedBy: string;
	performedOn?: Date;
	type: AccountActionType;
}

export enum AccountActionStatus {
	Pending = 'Pending',
	Completed = 'Completed',
}

export enum AccountActionType {
	ShowTextingCampaignRegistrationWelcomeDialog = 'ShowTextingCampaignRegistrationWelcomeDialog',
}

export enum PendingActions {
	ReconnectEmail = 'ReconnectEmail',
	ConnectEmailInitial = 'ConnectEmail_Initial',
	ConnectEmailInvalidateAccess = 'ConnectEmail_InvalidatedAccess',
	ConnectRedtail = 'ConnectRedtail_Initial',
	ConnectClio = 'ConnectClio_Initial',
	ConnectGoogleBusiness = 'ConnectGoogleBusiness_Initial',
	ConnectMyCase = 'ConnectMyCase_Initial',
	ConnectQQCatalyst = 'ConnectQQCatalyst_Initial',
	ConnectWealthbox = 'ConnectWealthbox_Initial',
	/** A MergeAccount integration has been enabled on the account and a user needs to connect their account. */
	ConnectMergeAccounting = 'ConnectMergeAccounting_Initial',
	/** A MergeCrm integration has been enabled on the account and a user needs to connect their account. */
	ConnectMergeCrm = 'ConnectMergeCrm_Initial',
	TextingRegistrationBrandRequired = 'TextingRegistration_BrandRequired',
	ConnectDonorPerfect = 'ConnectDonorPerfect_Initial',
}

export enum TemplateStatus {
	Archived = 'Archived',
	Disabled = 'Disabled',
	Draft = 'Draft',
	Published = 'Published',
}

export interface ILastUsedByDate {
	currentUser?: string;
	account?: string;
}

export interface IBaseTemplate extends IBaseApiModel {
	associatedTemplates?: IAssociatedTemplate[];
	attachments?: (IFileAttachment | IFileAttachmentWithURL)[];
	citation?: string;
	creationDate?: string;
	creator?: IUserReference;
	defaultTemplate?: boolean;
	enabled?: boolean;
	industries?: Industry[];
	lastModifiedDate?: string;
	name?: string;
	schedule?: ISuggestedSendSchedule;
	scope?: TemplateScope;
	status?: TemplateStatus;
	subject?: string;
	templateType?: TemplateType;
	keywords?: string[];
	summary?: string;
	lastUsedByDate?: ILastUsedByDate;
	contactFilter?: IContactsFilterRequest;
}

export interface ITemplate extends IBaseTemplate {
	content?: IRawRichTextContentState;
	language?: Language;
	languageDescription?: string;
	socialMedia?: IEmbeddedSocialMediaTemplateContent;
}

export interface IBlogTemplate extends IBaseTemplate {
	content?: IRawRichTextContentState;
	statuses?: ITemplateStatus[];
	prompt?: IRawRichTextContentState;
	title?: string;
	scheduledSendDate?: string;
	mainImage?: IFileAttachmentWithURL;
	templateId?: string;
	sendFromUserId?: string;
	contentCalendarSuggestionId?: string;
}
export interface IAssociatedTemplate {
	id: string;
	templateType: TemplateType;
}

export interface IBusinessCardFeatures {
	serverProcessing?: boolean;
}

export interface ITagFeatures {
	canManageTags?: boolean;
	canSetTagNotifications?: boolean;
}

export interface IFeature {
	enabled?: boolean;
}

export enum LevitateUIStimulant {
	/** A lighter version of Levitate with fewer bells and whistles */
	Decaf = 'Decaf',
	/** Full throttle Levitate */
	Caffeinated = 'Caffeinated',
}

export interface IAIDAFeature extends IFeature {
	achievementsEnabled?: boolean;
	allowAnonymousAccessToCallRecording?: boolean;
	allowEmailToSkipToNextLead: boolean;
	allowExport?: boolean;
	allowUsersToSelectRule?: boolean;
	levitateUIStimulant?: LevitateUIStimulant;
	propertyMappingEnabled?: boolean;
	transcriptionOnDemandEnabled?: boolean;
}

export interface IAutomationFeatures extends IFeature {
	additionalAllowedTriggers?: AutomationTriggerType[];
	autoStartForLeads?: boolean;
	additionalAllowedTextStepTriggers?: AutomationTriggerType[];
	allowAdminToStartOnBehalf?: boolean;
	allowStartAutomationsOnHold?: boolean;
	showInternalSendOption?: boolean;
}

export type ISocialMediaFeature = IFeature;

export interface IBlogFeature extends IFeature {
	domain?: string;
	allowedOrigins?: string[];
	blogApiKey?: string;
	dudaSiteId?: string;
}
export interface IMeetingSchedulerFeature extends IFeature {
	allowCompanyNameRequired?: boolean;
}

export type ITranslationFeature = IFeature;

export type IContentGenerationFeature = IFeature;

export type IConnectionTypesFeature = IFeature;

export type IPublicAPIFeature = IFeature;

export interface IHandwrittenCardsFeature extends IFeature {
	creditCostPerCard?: number;
	currencyCostPerCard?: number;
	currencyCostPerCredit?: number;
	isCompAccount?: boolean;
	isCustomizationEnabled?: boolean;
	hasDiscountedRate?: boolean;
}

export interface IHouseholdsFeature extends IFeature {
	sendToHouseholdByDefault?: boolean;
}

export interface IWebsitesFeature extends IFeature {
	tier?: string;
}

export interface IAccountFeatures {
	aida?: IAIDAFeature;
	automation?: IAutomationFeatures;
	blogFeature?: IBlogFeature;
	boards?: IBoardFeatures;
	businessCards?: IBusinessCardFeatures;
	connectionTypes?: IConnectionTypesFeature;
	contentGeneration?: IContentGenerationFeature;
	emailScan?: IEmailScanFeatures;
	handwrittenCards?: IHandwrittenCardsFeature;
	households?: IHouseholdsFeature;
	htmlNewsletter?: IHtmlNewsletterFeatures;
	meetingScheduler?: IMeetingSchedulerFeature;
	publicApi?: IPublicAPIFeature;
	sendEmail?: ISendEmailFeatures;
	socialMedia?: ISocialMediaFeature;
	surveys?: ISurveysFeature;
	tags?: ITagFeatures;
	texting?: ITextingFeature;
	timeline?: IFeature;
	translation?: ITranslationFeature;
	websites?: IWebsitesFeature;
}

export interface IFeatureBase {
	enabled?: boolean;
}

export interface ISurveysFeature extends IFeatureBase {
	defaultReviewLink?: string;
}

export enum ETextingFeatureProvider {
	Bandwidth = 'Bandwidth',
	Twilio = 'Twilio',
}

export interface ITextingFeature extends IFeatureBase {
	requiresArchiving?: boolean;
	defaultReviewLink?: string;
	provider?: ETextingFeatureProvider;
	secondsBetweenSends?: number;
	sendLimits?: IRateLimit[];
	sendIntervals?: SendIntervals;
}

export interface IHtmlNewsletterFeatures {
	enabled?: boolean;
	maximumContactsPerSend?: number;
	unsubscribeTemplateId?: string;
	useClientEmailProvider?: boolean;
	domainName?: string;
	isConfigured?: boolean;
}

export interface IProviderEmailFeatures {
	canSendMessages?: boolean;
	canViewHistoryByContact?: boolean;
	canViewInbox?: boolean;
	canViewSentMessages?: boolean;
}

export interface IProviderCalendarFeatures {
	canAddUpdateEvents?: boolean;
	canViewHistoryAll?: boolean;
	canViewHistoryByContact?: boolean;
}

export interface IProviderContactFeatures {
	canOneWayContactSync?: boolean;
}

export interface IVerifyToken {
	accountId: string;
	token: string;
	userId: string;
}

export interface IBusinessCardSuggestion extends IBaseApiModel {
	suggestedCompany?: ICompany;
	suggestedContact: IContact;
}

export interface IBusinessCardCreateRequest {
	company?: Partial<ICompany>;
	contact: Partial<IContact>;
}

export interface IBusinessCardCreateResponse {
	company?: Partial<ICompany>;
	contact: Partial<IContact>;
}

export enum DashboardSuppressContext {
	All = 0,
	RecentMeeting,
	UpcomingMeeting,
	EmailActivity,
	ClassifyContact,
	Note,
	ActionItem,
	SuggestedContact,
	UpcomingKeyFact,
	UpcomingAcknowledgeableKeyDates,
	RecentBulkEmails,
	UpcomingRenewalSnooze,
}

export interface IDashboardSuppress extends IBaseApiModel {
	context: DashboardSuppressContext;
}

export interface IQueryStringCommand {
	command: string;
}

export interface IKeepInTouchCommand extends IQueryStringCommand {
	actionItemId?: string;
	contactId: string;
	isSuggestion?: boolean;
}

export interface IRecentMeetingCommand extends IQueryStringCommand, IBaseApiModel {
	itemSignature?: string;
}

export type TemplateCommandOperation = 'send' | 'bulkSend' | 'edit';

export type TemplateSendOption = 'now' | 'later';

export interface ITemplateCommand extends IQueryStringCommand, IBaseApiModel {
	op?: TemplateCommandOperation;
	template?: ITemplate;
	tags?: string[];
	sendOption?: TemplateSendOption;
	date?: string;
}

export interface ITemplateCategory {
	total: number;
	templates: ITemplateWithStatus[];
	name: string;
	type: TemplateCategoryType;
}

export enum TemplateCategoryType {
	Featured = 'Featured',
	Archived = 'Archived',
	Uncategorized = 'Uncategorized',
	Default = 'Default',
	HtmlNewsletter = 'HtmlNewsletter',
}

export interface ITemplateStatus {
	industry?: Industry;
	category?: string;
	status?: TemplateStatus;
}

export interface ITemplateWithStatus {
	status: TemplateStatus;
	templateId: string;
}

export enum TagManagementCommandOperation {
	SetTagNotification = 'SetTagNotification',
}

export interface ITagManagementCommand extends IQueryStringCommand, IBaseApiModel {
	op?: TagManagementCommandOperation;
	tag?: string;
}

export type DashboardFeedFilterType =
	| 'ActionItem'
	| 'Reminder'
	| 'ClassifyContact'
	| 'Note'
	| 'Meeting'
	| 'EmailActivity';

export const AllDashboardFeedFilterValues: DashboardFeedFilterType[] = [
	'ActionItem',
	'Reminder',
	'ClassifyContact',
	'Note',
	'Meeting',
	'EmailActivity',
];

export interface IUpcomingKeyFact extends IBaseApiModel {
	contact: Partial<IContact>;
	keyFact: IKeyFact;
}

export interface IUpcomingBirthdays extends IBaseApiModel {
	keyFacts: IUpcomingKeyFact[];
	totalUpcoming: number;
}

export interface IUpcomingAnniversaries extends IBaseApiModel {
	keyFacts: IUpcomingKeyFact[];
	totalUpcoming: number;
}

export interface IUpcomingRenewals extends IBaseApiModel {
	keyFacts: IUpcomingKeyFact[];
	totalUpcoming: number;
}

export interface IDashboardFeedFilterCommand extends IQueryStringCommand, IBaseApiModel {
	activeFilters?: DashboardFeedFilterType[];
}

export interface IOwnerTagReportCommand extends IQueryStringCommand {
	reportId?: string;
}

export interface ICreateEntityRichContentCommand extends IQueryStringCommand {
	refs?: {
		companies?: string[];
		contacts?: string[];
	};
	type: 'note' | 'actionItem';
}

export type IKeepInTouchRemindersCommand = IQueryStringCommand;

export type IIndividualSendCommand = IQueryStringCommand;

export interface IBulkEmailApprovalCommand extends IQueryStringCommand {
	campaignId?: string;
}

export interface IBccTransaction extends IBaseApiModel {
	bccEmailAddress?: string;
	followUpReminderInDays?: number;
	saveAsNote?: boolean;
	noteVisibility?: ResourceVisibility;
	trackingPixelHtml?: string;
	trackingPixelId?: string;
}

export interface IBaseResourceModel extends IBaseApiModel {
	creationDate?: string;
	creator?: Partial<IUser>;
	lastModifiedDate?: string;
}

export enum BoardTemplateType {
	Opportunity = 0,
}

export type IBoardStageRollup = Record<string, unknown>;

export interface IOpportunityBoardStageRollup extends IBoardStageRollup {
	average?: number;
	total?: number;
}

export interface IBoardStageConfig {
	enableStageIndicator?: boolean;
}

export interface IBoardStageReference extends IBaseApiModel {
	boardIndex?: number;
	boardTotalStageCount?: number;
	config?: IBoardStageConfig;
	name?: string;
	rollup?: IBoardStageRollup;
	templateType?: BoardTemplateType;
}

export interface IBoardStage extends IBoardStageReference {
	board?: IBoardReference;
}

export interface IBoardReference extends IBaseApiModel {
	name?: string;
}

export interface IBoardTemplate {
	type?: BoardTemplateType;
}

export interface IBoard extends IBoardReference {
	stages?: IBoardStageReference[];
	template?: IBoardTemplate;
}

export interface IBoardItemSnapshot {
	board?: IBoard;
	stage?: IBoardStage;
}

export interface IBoardItem extends IBaseApiModel {
	archivedDate?: string;
	archivedState?: IBoardItemSnapshot;
	assignees?: Partial<IUser>[];
	boardStage?: IBoardStageReference;
	creator?: Partial<IUser>;
	isArchived?: boolean;
	position?: number;
}

export interface IOpportunity extends IBoardItem {
	closeDate?: string;
	company?: Partial<ICompany>;
	dealSize?: number;
	details?: IRawRichTextContentState;
	name?: string;
	primaryContact?: Partial<IContact>;
}

export interface IBoardItemActivity extends IBaseResourceModel {
	boardItemId?: string;
	eventType?: string;
	text?: string;
}

export enum MoveBoardItemPosition {
	Before = 0,
	After,
}

export interface IMoveBoardItem {
	// used only if item at relativeToItemId does not exist... this is a preferred approx. index.
	fallbackIndex?: number;
	position?: MoveBoardItemPosition;
	relativeToItemId?: string;
	stageId?: string;
}

export interface IRichContentPermissions {
	canDelete?: boolean;
	canUpdate?: boolean;
}

export interface ITagStats {
	companies: number;
	contacts: number;
	total: number;
}

export enum FavoriteFor {
	None = 'None',
	User = 'User',
	Account = 'Account',
}

export interface IAccountTag {
	id?: string;
	automationTemplateId?: string;
	category?: string;
	favoriteFor?: FavoriteFor;
	source?: IntegrationSources;
	stats?: ITagStats;
	tag?: string;
	tagAlert?: ITagAlert;
}

export interface ITagInteractionFilterOptions {
	accountWide: boolean;
	resourceOwner: boolean;
	user?: UserReference;
}

export interface ITagNotifyOptions {
	resourceOwner: boolean;
	user?: UserReference;
}

export interface ITagAlert extends IBaseApiModel {
	interactionFilter?: ITagInteractionFilterOptions;
	interactionIntervalInDays: number;
	lastReportSendDate?: string; // date
	requireValidEmailAddress?: boolean;
	sendAlertsTo?: ITagNotifyOptions;
	tag?: string;
}

export interface IMergeTagsRequest {
	intoTag?: string;
	sourceTags?: string[];
}

export enum OpportunitySearchProperty {
	Name = 0,
	Company,
	PrimaryContact,
	Owner,
}

export interface IRange<T> {
	end?: T;
	start?: T;
}

export interface IBoardItemSearchRequest<TProperty> {
	stageId?: string;
	searchProperty?: TProperty;
	searchValue?: string;
}

export interface IOpportunitySearchRequest extends IBoardItemSearchRequest<OpportunitySearchProperty> {
	closeDate?: IRange<string>;
	dealSize?: IRange<number>;
	stageId?: string;
}

export interface IBoardExportRequest {
	filter: IOpportunitySearchRequest;
	id: string;
	includeActivity: boolean;
}

export interface IEmailExportRequest {
	id: string;
}

export interface IFilterCriteria<TProperty = any, TOperator = FilterOperator> {
	criteria?: IFilterCriteria<TProperty, TOperator>[];
	/** If omitted, criteria that will be and'd together */
	op?: TOperator;
	property?: TProperty;
	value?: string;
}
export type IRichContentFilterCriteria = IFilterCriteria<RichContentProperty>;

export type IRichContentRequest = IRichContentFilterCriteria;

export type ICampaignFollowupCriteria = IFilterCriteria<EmailTransactionStatus, FilterOperator>;

export enum DonorFilterProperty {
	Name = 'Name',
	LastDonationDate = 'LastDonationDate',
	Lifetime = 'Lifetime',
	ContactId = 'ContactId',
}
export type IDonorFilterCriteria = IFilterCriteria<DonorFilterProperty>;
export type IDonorFilterRequest = IDonorFilterCriteria;

export enum DonationFilterProperty {
	Amount = 'Amount',
	Date = 'Date',
	Campaign = 'Campaign',
	PaymentMethod = 'PaymentMethod',
	DonorId = 'DonorId',
	ContactId = 'ContactId',
}

export type IDonationFilterCriteria = IFilterCriteria<DonationFilterProperty>;
export type IDonationFilterRequest = IDonationFilterCriteria;

export enum InsurancePolicyFilterProperty {
	Contact = 'Contact',
	LineOfBusiness = 'LineOfBusiness',
	RenewalDate = 'RenewalDate',
	Carrier = 'Carrier',
	Premium = 'Premium',
	UmbrellaPolicy = 'UmbrellaPolicy',
	PolicyNumber = 'PolicyNumber',
	EffectiveDate = 'EffectiveDate',
	ContactId = 'ContactId',
	OwnerId = 'OwnerId',
}

export type IInsurancePolicyFilterCriteria = IFilterCriteria<InsurancePolicyFilterProperty>;
export type IInsurancePolicyFilterRequest = IInsurancePolicyFilterCriteria;

export enum ContactFilterCriteriaProperty {
	All = 0,
	Connections = 1,
	CreatedBy = 2,
	OwnedBy = 3,
	PrivateContacts = 4,
	KeepInTouch = 5,
	TagAlert = 6,
	Name = 7,
	Company = 8,
	Tag = 9,
	Email = 10,
	UpcomingKeyDates = 11,
	UpcomingKeyDatesNotScheduled = 12,
	WithEmailAddress = 13,
	WithoutEmailAddresses = 14,
	WithoutTags = 15,
	InProgressAutomations = 16,
	OnHoldAutomations = 17,
	PreviousAutomations = 18,
	Policy = 19,
	KeyDate = 20,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	KeyDateKind = 21,
	KeyDateYear = 22,
	KeyDateSuppressList = 23,
	KeyDateSuppressDate = 24,
	Archived = 25,
	CapableOf = 26,
	PhoneNumber = 27, // phone number search, not presence of phone
	Source = 28,
	WithMailingAddress = 29,
	ConnectionTypeOrOwner = 30,
	WithPhoneNumber = 31,
	WithoutPhoneNumber = 32,
	HasDuplicates = 33,
}

export const CONTACT_FILTER_OPERATOR = {
	And: 'And',
	Gte: 'Gte',
	Lte: 'Lte',
	Not: 'Not',
	Or: 'Or',
} as const;
export type ContactFilterOperator = ObjectValues<typeof CONTACT_FILTER_OPERATOR>;

// TODO: Remove Add, Gte, Lte from here since it doesn't match up with the platform type.
// TODO: Update IContactFilterCriteria's op to use ContactFilterOperator which supports Gte, Lte and not Gt, Lt.
export enum FilterOperator {
	Add = 'Add',
	And = 'And',
	Gt = 'Gt',
	Lt = 'Lt',
	Not = 'Not',
	Or = 'Or',
	Gte = 'Gte',
	Lte = 'Lte',
}

export type IContactFilterCriteria = {
	criteria?: IContactFilterCriteria[];
	op?: ContactFilterOperator;
	property?: ContactFilterCriteriaProperty;
	value?: string;
	values?: string[];
};

export type FilterRequestBase<
	TProperty = any,
	TFilterCriteriaOperator = FilterOperator,
	TCriteria = IFilterCriteria<TProperty, TFilterCriteriaOperator>,
> = {
	criteria?: TCriteria[];
	op?: FilterOperator;
};

export type IContactsFilterRequest = IContactFilterCriteria;

export interface ISetContactVisibilityRequest {
	ids?: string[];
	visibility?: ResourceVisibility;
}

export interface IViewTagAlertContactsCommand extends IQueryStringCommand {
	id?: string;
	op?: 'send' | 'view';
}

export interface IViewTagContactsCommand extends IQueryStringCommand {
	id?: string;
	op?: 'send' | 'view';
}

export type RelationshipHealthStatus = 'Healthy' | 'Neutral' | 'Unhealthy';

export interface IRelationshipHealth {
	/** Can be null if no interaction */
	lastInteractionDate?: string;

	/** Can be null if no interaction */
	status?: RelationshipHealthStatus;

	/** Can be null if no interaction */
	userLastInteractionDate?: string;
}

export interface IEntityReportRequest {
	endDate: string;
	startDate: string;
	/** Used limit/filter report to specific user. Omit this to get a report pertaining to the entire account. */
	userId?: string;
}

export interface IEntityReportByTagsRequest extends IEntityReportRequest {
	tags?: string[];
}

export interface IEntityReportBySearchQueryRequest extends IEntityReportRequest {
	searchQuery?: string;
}

export interface IEntityReportByIdRequest extends IEntityReportRequest {
	entityId?: string;
}

/** Note: "daily" or "weeklyDays" must be supplied */
export interface IKeepInTouchCommitmentPreferences {
	calendarBlockBusy: boolean;
	calendarBlockInMinutes?: number;
	daily?: boolean;
	weeklyDays?: DayOfWeek[];
}

export enum DayOfWeek {
	Sunday = 0,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday,
}

export interface IBulkContactsRequest {
	excludeContactIds?: string[];
	filter?: IContactsFilterRequest;
	includeContactIds?: string[];
	groupByHousehold?: boolean;
	groupByDuplicate?: boolean;
	groupByHouseholdFilter?: IContactsFilterRequest;
}

export type IContactsExportRequest = IBulkContactsRequest;
export interface ICompanyExportRequest {
	includeContacts: boolean;
	excludeCompanyIds?: string[];
	deprecatedFilter?: ICompaniesSearchRequest;
	filter?: IFilterCriteria<CompanyViewModel>;
	includeCompanyIds?: string[];
}

export interface IContactsAddTagsRequest extends IBulkContactsRequest {
	tags: string[];
}

export enum CompanySearchRequestProperty {
	All = 0,
	Name,
	Tag,
}

export type ICompaniesSearchFilter = IFilterCriteria<CompanySearchRequestProperty>;

export interface ICompaniesSearchRequest {
	/** Search parameters that will be and'd together */
	searches?: ICompaniesSearchFilter[];
}

export interface ICampaignReportRequest {
	endDate?: Date;
	filter?: IFilterCriteria<BulkEmailFilterProperty>;
	startDate?: Date;
	userId?: string;
}

export type ContactTypeEnum = 'Employee' | 'ExternalContact';

/**
 * @export
 * @interface ProjectedContact
 */
export interface IProjectedContact {
	company?: ICompany;
	companyId?: string;
	companyName?: string;
	contactType?: ContactTypeEnum;
	creatorId: string;
	emailAddresses?: EmailAddress[];
	firstName?: string;
	handle?: string;
	id: string;
	jobTitle?: string;
	lastName?: string;
	ownerId?: string;
	primaryEmail?: EmailAddress;
	profilePic?: string;
	tags?: string[];
}

/**
 * @export
 * @interface UserReference
 */
export interface IUserReference {
	// email?: string; // it will come back but let's not expose it
	firstName?: string;
	id: string;
	lastName?: string;
	primaryEmail?: EmailAddress;
	profilePic?: string;
}

/**
 * @export
 * @interface ContactOwnerReference
 */
export interface IContactOwnerReference {
	user: IUserReference;
	contactsCount: number;
}

/**
 * @export
 * @interface CampaignTransactionsRequest
 */
export interface ICampaignTransactionsRequest {
	clickedTransactions?: boolean;
	expandGroup?: boolean;
	fragment?: string;
	id?: string;
	status?: EmailTransactionStatus;
}

export interface IToRecipient {
	contact: IProjectedContact;
	displayName?: string;
	hasReplied: boolean;
	sentToEmailAddress?: string;
}

export interface IClickTarget {
	firstClickDate?: string; // Date;
	id: string;
	innerText: string;
	lastClickDate?: string; // Date;
	totalClicks: number;
	url: string;
}

/**
 * @export
 * @interface CampaignEmail
 */
export interface ICampaignEmail extends IBaseApiModel {
	clickDate?: string; // Date;
	clickTargets: IClickTarget[];
	creationDate: string; // Date;
	creator: IUserReference;
	isCustom: boolean;
	lastModifiedDate?: string; // Date;
	openDate?: string; // Date;
	repliedDate?: string; // Date;
	sentDate?: string; // Date;
	status: EmailTransactionStatus;
	tags?: string[];
	toRecipients?: Partial<IToRecipient>[];
}

/**
 * @export
 * @interface ICampaignEmailContent
 */
export interface ICampaignEmailContent extends ICampaignEmail {
	subject: string;
	htmlContent: string;
}

export enum EmailCategories {
	Anniversary = 'Anniversary',
	Automation = 'Automation',
	Birthday = 'Birthday',
	ContentCalendar = 'ContentCalendar',
	FinancialReview = 'FinancialReview',
	HtmlNewsletter = 'HtmlNewsletter',
	Immediately = 'Immediately',
	Individual = 'Individual',
	KeyDate = 'KeyDate',
	Notification = 'Notification',
	OnBehalf = 'OnBehalf',
	OtherKeyDates = 'OtherKeyDates',
	Renewal = 'Renewal',
	ResourceSelector = 'ResourceSelector',
	ReviewAndApprove = 'ReviewAndApprove',
	UsingTemplate = 'UsingTemplate',
}

/**
 * @export
 * @interface Campaign
 */
export interface ICampaign {
	actor?: IActor;
	approvalRequests?: ICampaignApprovalRequest[];
	cancelledDate?: Date | string;
	categories?: EmailCategories[];
	citation?: string;
	clickCount?: number;
	clickTrackingEnabled?: boolean;
	completedDate?: Date | string;
	creationDate?: Date | string; // Date;
	creator?: IUserReference;
	distinctClickCount?: number;
	endResolutionDate?: string; // Date
	failedCount?: number;
	filterRequest?: IContactsFilterRequest;
	groupId?: string;
	sendToHousehold?: boolean;
	hasContactExclusions?: boolean;
	hasResolvedTransactions?: boolean;
	id?: string;
	lastCommErrorDate?: Date | string;
	lastDetailedStatus?: IEmailMessageDetailedStatus;
	lastModifiedDate?: string; // Date;
	machineOpenObserved?: boolean;
	name?: string;
	noEmailAddressCount?: number;
	openCount?: number;
	replyCount?: number;
	sampleContacts?: IProjectedContact[];
	schedule?: IScheduledSend;
	sentSuccessfullyCount?: number;
	startResolutionDate?: string; // Date
	status?: EmailSendStatus;
	subject?: string;
	templateReference?: ITemplateReference;
	totalContacts?: number;
	totalEmails?: number;
	attachments?: IFileAttachment[];
}

export interface ICommunicationStatsCampaign {
	completedDate?: string; // Date
	id: string;
	subject?: string;
	tags?: string[];
	totalEmails?: number;
	totalEmailsSent?: number;
	openCount?: number;
	replyCount?: number;
}

export interface ICommunicationStats {
	averageOpenRate?: number;
	averageReplyRate?: number;
	featuredTemplates?: ITemplate[];
	creationDate?: string; // Date
	mostRecentCampaign?: ICommunicationStatsCampaign;
	totalEmailsSent?: number;
	totalTextsSent?: number;
}

export interface ISatisfactionSurveyCardData {
	averageScore?: number;
	recentResponses?: ISatisfactionSurveyResponse[];
}

export interface ISocialMediaCard {
	featuredTemplates?: ITemplate[];
	totalPosts?: number;
	recentPosts?: ISocialMediaPost[];
}

export interface IEmailMessageDetailedStatus {
	approxmiateNextSendAttempt?: Date;
	statusDate?: Date;
	statusCode: EmailDetailedStatusCode;
}

export enum EmailDetailedStatusCode {
	Unknown = 'Unknown',
	BusinessHourRestriction = 'BusinessHourRestriction',
	CapacityRestriction = 'CapacityRestriction',
	EmailProviderReconnect = 'EmailProviderReconnect',
	Sending = 'Sending',
	Cancelled = 'Cancelled',
	EmailProviderReconnect_Cancelled = 'EmailProviderReconnect_Cancelled',
	Complete = 'Complete',
	Expired_Complete = 'Expired_Complete',
}

export interface IAggregatedBulkEmailMessages {
	count: number;
	end: string; // Date;
	start: string; // Date;
	totalContacts: number;
	totalEmails?: number;
	totalOpened: number;
	totalReplied: number;
	totalSent: number;
	userId?: string;
}

export interface IReachOutInfo {
	countByTag?: ICountByTag[];
	// Tag alerts
	tagAlertCount?: number;
	tagAlertContacts?: IProjectedContact[]; // 3 max
	// Keep in touches
	keepInTouchCount?: number;
	keepInTouchContacts?: IProjectedContact[]; // 3 max

	tagsWithAlerts?: string[];

	// deprecated
	totalCount: number;
	keepInTouchDue?: IContact[];
}

export enum InteractionType {
	NoteMention = 0,
	SentEmail,
	Meeting,
	PhoneCall,
	TextMessage,
}

export interface IMostRecentInteraction {
	interactionDate?: string;
	interactionType: InteractionType;
}

/** Note: id === contact.id */
export interface IClassifyContact extends IBaseApiModel {
	contact: IContact;
	mostRecentInteraction?: IMostRecentInteraction;
	suggestedTags?: string[];
	recentEmailSubjects?: string[];
}

export type DashboardFeedItemModel = IMeeting | IActionItem | INote | IClassifyContact | IEmailActivity;

export interface IDashboardFeedItem extends IBaseApiTypeModel {
	model: DashboardFeedItemModel;
	score: number;
}

export enum NetworkProxyType {
	None = 'None',
	Socks5 = 'Socks5',
}

export enum NetworkProxyEndpoint {
	None = 'None',
	Custom = 'Custom',
	Azure = 'Azure',
	DigitalOcean = 'DigitalOcean',
	Linode = 'Linode',
}

export interface INetworkProxy {
	endpoint?: NetworkProxyEndpoint;
	endpointHostOverride?: string;
	endpointPortOverride?: number;
	type?: NetworkProxyType;
}

export interface IEmailWorkloadStat {
	count: number;
	intervalInMinutes: number;
}

export interface IEmailWorkloadLimit {
	count: number;
	intervalInMinutes: number;
}

export interface IEmailDayWorkload {
	count: number;
	date: string;
}

export interface IEmailWorkload {
	forecast: IEmailDayWorkload[];
	limits: IEmailWorkloadLimit[];
	stats: IEmailWorkloadStat[];
}

export enum ScheduleCriteria {
	CompleteBy = 'CompleteBy',
	Immediately = 'Immediately',
	OnKeyDate = 'OnKeyDate',
	OnHold = 'OnHold',
	StartAfter = 'StartAfter',
	Undefined = 'Undefined',
	OnDayOf = 'OnDayOf',
	OnBirthday = -1,
}

export interface IScheduledSend {
	criteria?: ScheduleCriteria;
	endDate?: string;
	expirationDate?: string;
	startDate?: string;
}

export interface ISuggestedSendSchedule {
	expirationDate?: string;
	startDate?: string;
}

/** Extended version of the Systemjob with preview of Schedule */
export interface ISendEmailResponse {
	id: string;
	schedule: IScheduledSend;
	suggestedSchedule?: ISuggestedSendSchedule;
	systemJob?: ISystemJob;
}

export interface IEmailSendEstimate {
	emailWorkload: IEmailWorkload;
	estimate: IScheduledSend;
}

export interface IImportContactFieldConfig {
	aliases?: string[];
	name?: string;
	/** Key path in IContact, e.g. Address.Address1, or Address.StateProvince */
	propertyName?: string;
	allowMultiple?: boolean;
}

export interface IImportContactPreview {
	fields?: IImportContactFieldConfig[];
	header?: string[];
	/** SystemJobId */
	id: string;
	/** These are the sample values for the table (e.g. this.sample[col][row]) */
	sample?: string[][];
}

export interface IImportContactsOptions {
	contentType: ImportContactsContentType;
	/** { [Header] : PropertyName } */
	excludeBaseTag?: boolean;
	fieldMapping: IDictionary<string>;
	preserveExistingFieldValues: IPreservableFieldValues[];
	tags: string[];
	useEmailAddressAsAlternativeKey?: boolean;
	allowMatchingByName: boolean;
	visibility?: ResourceVisibility;
	deleteRenewalKeyFacts?: boolean;
}

export type IPreservableFieldValues = 'First Name' | 'Last Name' | 'Display Name';

export interface IImportContactsJob extends ISystemJob {
	options?: IImportContactsOptions;
}

export enum ImportContactsContentType {
	csv = 'text/csv',
	xls = 'application/vnd.ms-excel',
	xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export interface IContactsAssignOwnerRequest {
	excludeContactIds?: string[];
	filter?: IContactsFilterRequest;
	includeContactIds?: string[];
	newOwnerId: string;
}

export interface IContactsAddConnectionRequest {
	excludeContactIds?: string[];
	filter?: IContactsFilterRequest;
	includeContactIds?: string[];
	newConnectionId: string;
}

export interface IContactsSetVisibilityRequest {
	excludeContactIds?: string[];
	filter?: IContactsFilterRequest;
	includeContactIds?: string[];
	visibility?: string;
}

export interface INotificationMethods {
	email?: boolean;
	pushNotifications?: boolean;
}

export enum NotificationProvider {
	Invalid = 'Invalid',
	Firebase = 'Firebase',
}

export interface IPushNotificationSubscriptionRequest {
	endPointUrl?: string;
	platform?: Platform;
	provider?: NotificationProvider;
	tokens?: string[];
}

export type IPushNotificationSubscription = IPushNotificationSubscriptionRequest & IBaseApiModel;

export interface IPushNotificationMessage {
	accountId?: string;
	clickUrl?: string;
	iconUrl?: string;
	id?: string;
	message?: string;
	/** JSON string of the form [{name: string, id: string},...] */
	referencedResources?: string;
	title?: string;
	type?: string;
	userId?: string;
}

export enum Platform {
	Unknown = 'Unknown',
	Android = 'Android',
	iOS = 'iOS',
	Web = 'Web',
}

export interface IIntegration {
	configured?: boolean;
	configuredDate?: string;
	enabled?: boolean;
	lastExtractDate?: string;
	lastIntegrationConnectionDate?: string;
	lastWriteBackDate?: string;
	preferredMappingKey?: IntegrationPreferredMappingKey;
}

export interface IIntegrationStatus {
	isConnected?: boolean;
	lastExtractDate?: string;
	lastLoadDate?: string;
}

export interface IIntegrationUserMapping {
	integrationId: string;
	levitateUser?: Partial<IUser>;
	connectionTypes?: string[];
}

export interface IAccountIntegrationCredentials {
	password: string;
	userName: string;
}

export enum CommercialProfiles {
	All = 'All',
	PrimarySecondaryOnly = 'PrimarySecondaryOnly',
	PrimaryOnly = 'PrimaryOnly',
}

export enum IntegrationPreferredMappingKey {
	Default = 'Default',
	Email = 'EmailAddress',
	ResourceId = 'ResourceId',
}

export enum PersonalProfiles {
	PrimarySecondaryOnly = 'PrimarySecondaryOnly',
	PrimaryOnly = 'PrimaryOnly',
}

export enum LifeHealthProfiles {
	PrimarySecondaryOnly = 'PrimarySecondaryOnly',
	PrimaryOnly = 'PrimaryOnly',
}

export enum ConfigurableIntegrationType {
	Ams360 = 'ams360',
	HawkSoft = 'hawksoft',
	Redtail = 'redtail',
	Eclipse = 'eclipse',
	Clio = 'clio',
	GoogleBusinessProfile = 'googleBusiness',
	Salesforce = 'Salesforce',
	EzLynx = 'ezlynxcsv',
	Epic = 'epicCsv',
	QQ = 'qqCatalyst',
	HubSpot = 'hubSpot',
	// This isn't a valid integrationProvider on the API.
	// The usage of this should be limited to just FE code. Any API calls, should translate into generalCsv
	Xanatek = 'Xanatek',
	GeneralCsv = 'generalCsv',
	Wealthbox = 'wealthbox',
	QuickBooksOnline = 'QuickBooksOnline',
	MergeAccounting = 'mergeAccounting',
	MergeCrm = 'mergeCrm',
	PublicAPI = 'publicApi',
	Xero = 'Xero',
	MyCase = 'myCase',
	DonorPerfect = 'donorPerfect',
}

export type MergeLinkConfigurableIntegrationType =
	| ConfigurableIntegrationType.MergeAccounting
	| ConfigurableIntegrationType.MergeCrm;

const GeneralCsvProviders = [ConfigurableIntegrationType.Xanatek, ConfigurableIntegrationType.GeneralCsv];
export const isGeneralCsvIntegration = (type: ConfigurableIntegrationType) => GeneralCsvProviders.includes(type);
const MergeAccountingProviders = [ConfigurableIntegrationType.QuickBooksOnline, ConfigurableIntegrationType.Xero];
export const isMergeAccountingIntegration = (type: ConfigurableIntegrationType) =>
	MergeAccountingProviders.includes(type);
const MergeCrmProviders = [ConfigurableIntegrationType.Salesforce];
export const isMergeCrmIntegration = (type: ConfigurableIntegrationType) => MergeCrmProviders.includes(type);

const MultiUserIntegrations = [
	ConfigurableIntegrationType.Redtail,
	ConfigurableIntegrationType.Clio,
	ConfigurableIntegrationType.QQ,
];
export const isMultiUserIntegration = (type: ConfigurableIntegrationType) => MultiUserIntegrations.includes(type);

const ReportBasedIntegrations = [
	ConfigurableIntegrationType.EzLynx,
	ConfigurableIntegrationType.Epic,
	ConfigurableIntegrationType.Xanatek,
	ConfigurableIntegrationType.GeneralCsv,
];
export const isReportBasedIntegration = (type: ConfigurableIntegrationType) => ReportBasedIntegrations.includes(type);

/*
 * Returns the root integration provider. For MergeAccounting, MergeCrm, and GeneralCsv, they are the top level integration with a subproperty that indicates the underlying source.
 */
export const resolveIntegrationProviderToApiType = (type: ConfigurableIntegrationType) => {
	if (isGeneralCsvIntegration(type)) {
		return ConfigurableIntegrationType.GeneralCsv;
	}
	if (isMergeAccountingIntegration(type)) {
		return ConfigurableIntegrationType.MergeAccounting;
	}
	if (isMergeCrmIntegration(type)) {
		return ConfigurableIntegrationType.MergeCrm;
	}
	return type;
};

export interface IIntegrationLock {
	createdOn?: string;
}

export interface IIntegrationLocks {
	ownershipRoleLock?: IIntegrationLock;
}
export interface IConfigurableIntegration extends IIntegration {
	agencyId?: string;
	agencyIds?: string[];
	commercialProfiles?: CommercialProfiles;
	integrationUserConfigurations?: IIntegrationUserConfiguration[];
	invalidOwnerships?: IInvalidOwnership[];
	isConnected?: boolean;
	lastConnectionStatus?: string;
	lastLoadDate?: string;
	locks?: IIntegrationLocks;
	prospectOptions?: IProspectOptions;
	primaryEmailOnly?: boolean;
	userMap?: IIntegrationUserMapping[];
	useSalutationAsFirstName?: boolean;
	segmentByTagValues?: ISegmentByTagValues;
}

export enum GeneralCsvProvider {
	Unknown = 'Unknown',
	Xanatek = 'Xanatek',
}

export interface IGeneralCsvIntegration extends IReportCsvIntegration {
	generalCsvProvider: GeneralCsvProvider;
	tagKeyFactOptions?: IInsuranceTagKeyFactOptions;
}

export interface IInvalidOwnership {
	integrationOwnerId?: string;
	notificationDate?: Date;
	previousIntegrationOwnerId?: string;
}

export interface IProspectOptions {
	prospectIntegration?: ProspectIntegration;
	timelineYears?: number;
}
export interface ISegmentByTagValues {
	enabled?: boolean;
	tagValues?: string[];
	containsAll?: boolean;
	exclude?: boolean;
}

export enum ProspectIntegration {
	None = 'None',
	All = 'All',
	Timeline = 'Timeline',
}

export interface ISubStatusOptions {
	subStatusOptions?: SubStatusIntegration[];
	cancelledYears?: number;
}

export enum SubStatusIntegration {
	Active = 'Active',
	Cancelled = 'Cancelled',
	Prospect = 'Prospect',
}

export interface IAms360Integration extends IConfigurableIntegration {
	credentials?: IAccountIntegrationCredentials;
	inactiveClientsEnabled?: boolean;
	lastNotificationReceivedDate?: string;
	ownershipRole?: Ams360IntegrationOwnershipRole;
	tagKeyFactOptions?: IAms360TagKeyFactOptions;
	userId?: string;
	writeOptions?: { writeBackTypes: WriteBackOption[] };
	segmentByTagValues?: ISegmentByTagValues;
	personalProfiles?: PersonalProfiles;
}

export enum BaseInsuranceIntegrationOwnershipRole {
	Producer = 'Producer',
	CSR = 'CSR',
}

export enum Ams360IntegrationOwnershipRole {
	Executive = 'Executive',
	Representative = 'Representative',
}

export enum RedtailIntegrationOwnershipRole {
	FirstObservedUserFromContact = 'FirstObservedUserFromContact',
	ServicingAdvisor = 'ServicingAdvisor',
	ServicingAdvisorRestricted = 'ServicingAdvisorRestricted',
	WritingAdvisor = 'WritingAdvisor',
	WritingAdvisorRestricted = 'WritingAdvisorRestricted',
}

export enum WriteBackOption {
	Email = 'Email',
	Note = 'Note',
	PhoneCall = 'PhoneCall',
	TextMessage = 'TextMessage',
}

export interface IIntegrationUserConfiguration extends IConfigurableIntegration {
	encryptedUserKeyId: string;
	levitateUserId: string;
}

export interface IRedtailIntegration extends IConfigurableIntegration {
	availableCategories: string[];
	availableContactTypes: string[];
	availableStatuses: string[];
	blockedCategories: string[];
	blockedContactTypes: string[];
	blockedStatuses: string[];
	ownershipRole: RedtailIntegrationOwnershipRole;
	redtailUserConfigurations: IIntegrationUserConfiguration[];
	primaryUser?: IIntegrationPrimaryUser;
	tagKeyFactOptions?: IRedtailTagKeyFactOptions;
	userMap: IIntegrationUserMapping[];
	writeOptions: { writeBackTypes: WriteBackOption[] };
	segmentByTagValues?: ISegmentByTagValues;
	useForMeetingScheduling?: boolean;
}

export interface IClioTagKeyFactOptions extends ITagKeyFactOptions {
	customFieldCheckboxTagEnabled: boolean;
	customFieldPicklistTagEnabled: boolean;
	groupPracticeAreaTagEnabled: boolean;
	groupPracticeAreaTagForRelatedEnabled: boolean;
	relationshipTagEnabled: boolean;
	matterNumberTagEnabled: boolean;
	responsibleAttorneyTagOption: AttorneyTagOption;
	originatingAttorneyTagOption: AttorneyTagOption;
	lastClosedMatterYearTagEnabled?: boolean;
}

export interface IMyCaseTagKeyFactOptions extends ITagKeyFactOptions {
	groupPracticeAreaTagEnabled: boolean;
	caseNumberTagEnabled: boolean;
	leadAttorneyTagOption: AttorneyTagOption;
	originatingAttorneyTagOption: AttorneyTagOption;
}

export interface IDonorPerfectTagKeyFactOptions extends ITagKeyFactOptions {
	donorTypeTagEnabled: boolean;
	majorDonorTagEnabled: boolean;
}

export type IHubspotTagKeyFactOptions = ITagKeyFactOptions;

export enum ClioIntegrationOwnershipRole {
	ResponsibleAttorney = 'ResponsibleAttorney',
	OriginatingAttorney = 'OriginatingAttorney',
}

export enum WealthboxIntegrationOwnershipRole {
	AssignedTo = 'AssignedTo',
}

export enum AttorneyTagOption {
	None = 'None',
	MostRecent = 'MostRecent',
	All = 'All',
}

export enum MergeAccountingProvider {
	QuickBooksOnline = 'QuickBooksOnline',
	Xero = 'Xero',
}

export enum MergeCrmProvider {
	Salesforce = 'Salesforce',
}

export enum MyCaseIntegrationOwnershipRole {
	LeadAttorney = 'LeadAttorney',
	OriginatingAttorney = 'OriginatingAttorney',
}

export enum DonorPerfectIntegrationOwnershipRole {
	DefaultOwner = 'DefaultOwner',
}

export type MergeLinkProvider = MergeAccountingProvider | MergeCrmProvider;

export enum MergeCrmIntegrationOwnershipRole {
	DefaultOwner = 'DefaultOwner',
}

export interface IIntegrationPrimaryUser {
	levitateUserId?: string;
	providerUserId?: string;
}

export interface IClioIntegration extends IConfigurableIntegration {
	blockedCustomFields: string[];
	clioTagKeyFactOptions?: any;
	clioWriteOptions?: any;
	integrationUserConfigurations?: IIntegrationUserConfiguration[];
	nonClients?: boolean;
	relatedContacts?: boolean;
	ownershipRole?: ClioIntegrationOwnershipRole;
	primaryUser?: IIntegrationPrimaryUser;
	tagKeyFactOptions?: IClioTagKeyFactOptions;
	writeOptions?: { writeBackTypes: WriteBackOption[] };
	segmentByTagValues?: ISegmentByTagValues;
}

export interface IHubSpotIntegration extends IConfigurableIntegration {
	apiKeyPlaceHolder?: string;
	tagKeyFactOptions?: IHubspotTagKeyFactOptions;
}

export interface IWealthboxIntegration extends IConfigurableIntegration {
	ownershipRole?: WealthboxIntegrationOwnershipRole;
	integrationUserConfiguration?: IIntegrationUserConfiguration;
	primaryUser?: IIntegrationPrimaryUser;
	tagKeyFactOptions?: IWealthboxTagKeyFactOptions;
	writeOptions?: { writeBackTypes: WriteBackOption[] };
	enabledCustomFields?: number[];
	availableCustomFields?: Record<string, string>;
}

export interface IMergeIntegration extends IConfigurableIntegration {
	mergeAccountId?: string;
	tagKeyFactOptions?: ITagKeyFactOptions;
	writeOptions?: { writeBackTypes: WriteBackOption[] };
}

export interface IMergeAccountingIntegration extends IMergeIntegration {
	ownershipRole?: MergeCrmIntegrationOwnershipRole;
	mergeAccountingProvider?: MergeAccountingProvider;
}

export interface IMergeCrmIntegration extends IMergeIntegration {
	ownershipRole?: MergeCrmIntegrationOwnershipRole;
	mergeCrmProvider?: MergeCrmProvider;
}

export interface IMyCaseCustomField {
	id?: number;
	name?: string;
	parentType?: string;
	fieldType?: string;
	listOptions?: IMyCaseCustomFieldListOption[];
}

export interface IMyCaseCustomFieldListOption {
	key?: string;
	option?: string;
}

export interface IMyCaseIntegration extends IConfigurableIntegration {
	myCaseTagKeyFactOptions?: IMyCaseTagKeyFactOptions;
	integrationUserConfigurations?: IIntegrationUserConfiguration[];
	ownershipRole?: MyCaseIntegrationOwnershipRole;
	tagKeyFactOptions?: IMyCaseTagKeyFactOptions;
	writeOptions?: { writeBackTypes: WriteBackOption[] };
	segmentByTagValues?: ISegmentByTagValues;
	syncUserId?: string;
	enabledCustomFields?: IMyCaseCustomField[];
	availableCustomFields?: IMyCaseCustomField[];
}

export interface IDonorPerfectIntegration extends IConfigurableIntegration {
	myCaseTagKeyFactOptions?: IDonorPerfectTagKeyFactOptions;
	integrationUserConfigurations?: IIntegrationUserConfiguration[];
	ownershipRole?: DonorPerfectIntegrationOwnershipRole;
	tagKeyFactOptions?: IDonorPerfectTagKeyFactOptions;
	segmentByTagValues?: ISegmentByTagValues;
}

export interface IAccountIntegrations {
	ams360?: IAms360Integration;
	clio?: IClioIntegration;
	eclipse?: IEclipseIntegration;
	epicCsv?: IEpicCsvIntegration;
	ezLynxCsv?: IEzLynxCsvIntegration;
	hawkSoft?: IHawkSoftIntegration;
	qqCatalyst?: IQQIntegration;
	redtail?: IRedtailIntegration;
	hubSpot?: IHubSpotIntegration;
	generalCsv?: IGeneralCsvIntegration;
	wealthbox?: IWealthboxIntegration;
	mergeAccounting?: IMergeAccountingIntegration;
	mergeCrm?: IMergeCrmIntegration;
	myCase?: IMyCaseIntegration;
	donorPerfect?: IDonorPerfectIntegration;
}

export enum BirthdayKeyFactDisplay {
	All = 'All',
	ClientOnly = 'ClientOnly',
	ProspectOnly = 'ProspectOnly',
}

export interface ITagKeyFactOptions extends IBaseApiTypeModel {
	birthdayKeyFactDisplay?: BirthdayKeyFactDisplay;
	stateTagEnabled?: boolean;
	cityTagEnabled?: boolean;
	postalCodeTagEnabled?: boolean;
}

export interface IInsuranceTagKeyFactOptions extends ITagKeyFactOptions {
	carrierTagEnabled?: boolean;
	displayBothOwnershipRoleTags?: boolean;
}

export interface IHawkSoftTagKeyFactOptions extends IInsuranceTagKeyFactOptions {
	officeLocationTagEnabled?: boolean;
	policyTitleTagEnabled?: boolean;
	relationshipTagEnabled?: boolean;
	sourceTagEnabled?: boolean;
	subStatusTagEnabled?: boolean;
	subStatusTagOption?: ISubStatusOptions;
	applicationTypeTagEnabled?: boolean;
	billingTypeTagEnabled?: boolean;
}

export interface IEclipseTagKeyFactOptions extends IInsuranceTagKeyFactOptions {
	policyTitleTagEnabled?: boolean;
	relationshipTagEnabled?: boolean;
	sourceTagEnabled?: boolean;
	subStatusTagEnabled?: boolean;
}

export interface IAms360TagKeyFactOptions extends IInsuranceTagKeyFactOptions {
	branchTagEnabled?: boolean;
	departmentTagEnabled?: boolean;
	divisionTagEnabled?: boolean;
	groupTagEnabled?: boolean;
	notationTagEnabled?: boolean;
}

export interface IQQTagKeyFactOptions extends IInsuranceTagKeyFactOptions {
	locationTagEnabled?: boolean;
}

export interface IFinancialReviewKeyFact {
	enabled?: boolean;
	property?: FinancialReviewKeyFactOptions;
}

export enum FinancialReviewKeyFactOptions {
	ClientSince = 'ClientSince',
	Birthdate = 'Birthdate',
	ClientReview = 'ClientReview',
}
export interface IRedtailTagKeyFactOptions extends ITagKeyFactOptions {
	categoryTagEnabled?: boolean;
	financialReviewOption?: IFinancialReviewKeyFact;
	keywordTagsEnabled?: boolean;
	sourceTagEnabled?: boolean;
}

export interface IWealthboxTagKeyFactOptions extends ITagKeyFactOptions {
	sourceTagEnabled?: boolean;
}

export interface IHawkSoftIntegration extends IConfigurableIntegration {
	officeIds?: string[];
	ownershipRole?: BaseInsuranceIntegrationOwnershipRole;
	tagKeyFactOptions?: IHawkSoftTagKeyFactOptions;
	segmentByTagValues?: ISegmentByTagValues;
	writeOptions?: { writeBackTypes: WriteBackOption[] };
	personalProfiles?: PersonalProfiles;
	lifeHealthProfiles?: LifeHealthProfiles;
}

export interface IQQIntegration extends IConfigurableIntegration {
	integrationUserConfigurations?: IIntegrationUserConfiguration[];
	ownershipRole?: BaseInsuranceIntegrationOwnershipRole;
	writeOptions?: { writeBackTypes: WriteBackOption[] };
	segmentByTagValues?: ISegmentByTagValues;
	tagKeyFactOptions?: IQQTagKeyFactOptions;
}

export interface IEclipseIntegration extends IConfigurableIntegration {
	apiToken?: string;
	officeIds?: string[];
	ownershipRole?: BaseInsuranceIntegrationOwnershipRole;
	tagKeyFactOptions?: IEclipseTagKeyFactOptions;
	segmentByTagValues?: ISegmentByTagValues;
	personalProfiles?: PersonalProfiles;
}

export interface IReportCsvIntegration extends IConfigurableIntegration {
	apiToken?: string;
	agencyIds?: string[];
	reportEmailAddress?: string;
	ownershipRole?: BaseInsuranceIntegrationOwnershipRole;
	ignoreContactNicknames?: boolean;
}

export interface IEzLynxTagKeyFactOptions extends IInsuranceTagKeyFactOptions {
	agencyCodeTagEnabled?: boolean;
	sourceTagEnabled?: boolean;
}

export interface IEpicTagKeyFactOptions extends IInsuranceTagKeyFactOptions {
	agencyCodeTagEnabled?: boolean;
}

export interface IEzLynxCsvIntegration extends IReportCsvIntegration {
	tagKeyFactOptions?: IEzLynxTagKeyFactOptions;
}

export interface IEpicCsvIntegration extends IReportCsvIntegration {
	ownershipRole?: BaseInsuranceIntegrationOwnershipRole;
	tagKeyFactOptions?: IEpicTagKeyFactOptions;
}

export interface IImpersonationContext {
	account: Partial<IAccount>;
	user?: Partial<IUser>;
}

export enum UserConfigurableIntegrationType {
	AdWerx = 'AdWerx',
	CRM = 'CRM',
	GoogleBusiness = 'GoogleBusiness',
	PublicAPI = 'PublicAPI',
}

export type IntegrationType = EmailScannerId | ConfigurableIntegrationType | UserConfigurableIntegrationType;
export interface IResourceUpdate {
	creatorId?: string;
	id: string;
}

export interface IBulkEmailUpdate extends IResourceUpdate {
	cancelledDate?: string;
	completedDate?: string;
	failedCount?: number;
	openCount?: number;
	replyCount?: number;
	noEmailAddressCount?: number;
	schedule: {
		criteria: ScheduleCriteria;
		startDate?: string;
		endDate?: string;
		expirationDate?: string;
	};
	sentSuccessfullyCount?: number;
	totalContacts: number;
	totalEmails: number;
}

export interface IEmailUpdateRecipient {
	companyName?: string;
	displayName?: string;
	emailAddress?: string;
	firstName?: string;
	id?: string;
	lastName?: string;
}

export interface IEmailUpdate extends IResourceUpdate {
	openDate?: string;
	repliedDate?: string;
	sentDate?: string;
	status?: EmailTransactionStatus;
	toRecipients?: IEmailUpdateRecipient[];
}

export enum EmailSendStatus {
	Cancelled = 'Cancelled',
	Complete = 'Complete',
	Draft = 'Draft',
	Queued = 'Queued',
	Ready = 'Ready',
	Rejected = 'Rejected',
	Sending = 'Sending',
	Unknown = 'Unknown',
	WaitingForApproval = 'WaitingForApproval',
}

export enum CampaignApprovalStatus {
	Approved = 'Approved',
	Rejected = 'Rejected',
}

export interface ICampaignApprovalResponse extends IBaseResourceModel {
	creatorId?: string;
	status: CampaignApprovalStatus;
}

export interface ICampaignApprovalRequest extends IBaseResourceModel {
	approver?: Partial<IUser>;
	content?: IRawRichTextContentState;
	response?: ICampaignApprovalResponse;
}
export interface IBlogPost extends BaseResourceModel {
	approvalRequests?: ICampaignApprovalRequest[];
	content: IRawRichTextContentState;
	mainImage: IFileAttachmentWithURL;
	scheduledSendDate?: string;
	sendFromUserId?: string;
	status?: BlogStatus;
	templateId?: string;
	title: string;
	actor?: IActor;
}

export interface IBlogCampaignApprovalRequest extends ICampaignApprovalRequest {
	blog: IBlogPost;
}

export interface IActor extends IBaseApiModel {
	accountId?: string;
	isLevitateSupport?: boolean;
	name?: string;
}

export interface IAIReference {
	sessionId: string;
}

export interface ITemplateReference {
	aiReference?: IAIReference;
	associatedTemplates?: IAssociatedTemplate[];
	isCustomized: boolean;
	isSystemTemplate: boolean;
	name?: string;
	templateId: string;
	templateType?: TemplateType;
}

export enum BulkEmailReportType {
	Automations = 'Automations',
	Campaigns = 'Campaigns',
	IndividualEmails = 'IndividualEmails',
}

export enum BulkEmailFilterProperty {
	All = 'All',
	Category = 'Category',
	IndividualSends = 'IndividualSends',
	OnlyCampaigns = 'OnlyCampaigns',
	/** Deprecated: Use IndividualSends instead of OnlyIndividualSends */
	OnlyIndividualSends = 'OnlyIndividualSends',
	OtherIndividualSends = 'OtherIndividualSends',
	ResourceSelector = 'ResourceSelector',
	Status = 'Status',
	User = 'User',
}

export type EmailReportingCategory = EmailCategories | BulkEmailFilterProperty.OtherIndividualSends;

export type IBulkEmailFilterCriteria = IFilterCriteria<BulkEmailFilterProperty>;

export interface ICreateAutomationForResourceSelectorRequest {
	automationTemplateId?: string;
	ownerIds?: string[];
	selectorRequest?: IResourceSelectorContactsRequest;
	sendFromOptions?: ISendFromOptions;
}

export interface ICreateAutomationsResult {
	existing?: string[];
	failed?: string[];
	success?: string[];
}

export interface IAutomationTemplateStats {
	cancelled?: number;
	completed?: number;
	inProgress?: number;
	lastUsed?: string; // date
}

export enum AutomationStepType {
	ActionItem = 'ActionItem',
	AddTag = 'AddTag',
	Email = 'Email',
	NoAction = 'NoAction',
	RemoveTag = 'RemoveTag',
	Switch = 'Switch',
	Texting = 'Texting',
	HandwrittenCard = 'HandwrittenCard',
}

export enum AutomationStepScheduleCriteria {
	AfterAutomationStart = 'AfterAutomationStart',
	Immediately = 'Immediately',
	RelativeToAnchor = 'RelativeToAnchor',
	Conditional = 'Conditional',
}

export enum AutomationStepOutcome {
	Always = 'Always',
	Error = 'Error',
	Never = 'Never',
	Success = 'Success',
}

export interface IAutomationStepSchedule {
	criteria?: AutomationStepScheduleCriteria;
	numberOfDays?: number;
	numberOfHours?: number;
	numberOfMinutes?: number;
}

// API Source: https://github.com/Real-Magic/Levitate-API/blob/master/src/Levitate.Api.Models/Automation/AutomationStepReference.cs#L5
export interface IAutomationStepReference extends IBaseApiTypeModel {
	id?: string;
	name?: string;
}

export type AutomationConditionalSteps = Partial<{
	[key in AutomationStepOutcome]: IAutomationStep;
}>;
export interface IAutomationStep extends IAutomationStepReference {
	conditionalSteps?: AutomationConditionalSteps;
	content?: IRawRichTextContentState;
	isDependant?: boolean;
	name?: string;
	schedule?: IAutomationStepSchedule;
	conditionalSchedule?: IAutomationStepSchedule;
	templateVersionId?: string;
	sendFromOptions?: ISendFromOptions | null;
}

/**
 * "Simplified version" of IAutomationStepCaseStatement questionable attempt to not misslead the frontend that it can
 * control the other fields
 */
export interface IAutomationStepCaseStatementBase {
	filter?: IContactFilterCriteria;
	isDefault?: boolean;
	name?: string;
}

export type IAutomationStepCaseStatementRequest = IAutomationStepCaseStatementBase;

/** Note: this is returned by the platform and should be considered immutable */
export interface IAutomationStepCaseStatement extends IAutomationStepCaseStatementBase {
	id?: string;
	automationTemplateId?: string;
}

export interface ISwitchAutomationStep extends IAutomationStep {
	cases?: IAutomationStepCaseStatement[];
	automationTemplateIds?: string[];
}

export interface IAutomationTemplateVersion<TTrigger extends IAutomationTrigger = IAutomationTrigger>
	extends IBaseApiModel {
	steps?: IAutomationStep[];
	trigger?: TTrigger;
}

export interface IAbstractAutomationTemplate extends IBaseTemplate {
	cancelOnReply?: boolean;
	stats?: IAutomationTemplateStats;
	templateType: TemplateType.Automation;
	sendToHousehold?: boolean;
}

export interface IAutomationTrigger extends IBaseApiModel {
	putOnHold?: boolean;
	resourceSelector?: ResourceSelectorId;
}

export interface IAvailableAutomationTrigger<T extends IAutomationTrigger = IAutomationTrigger> {
	availableStepTypes?: AutomationStepModelType[];
	name?: string;
	trigger?: T;
}

export interface ITagAutomationTrigger extends IAutomationTrigger {
	tag?: string;
}

export interface ITextingCampaignAutomationTrigger extends IAutomationTrigger {
	acknowledgedOnlySendingToOptedIn?: boolean;
}

export interface IResourceSelectorAutomationTrigger extends IAutomationTrigger {
	resourceSelector?: ResourceSelectorId;
}

/** A lead parsed by the EmailScannerService will trigger the automation */
export interface INewLeadAutomationTrigger extends IAutomationTrigger {
	/** Requires emailScannerIds to specify a custom scanner */
	customEmailScannerIds?: string[];
	/** E.g. allow sending outside of business hrs */
	disableObserveSendIntervals?: boolean;
	emailScannerIds?: EmailScannerId[];
	/** Only trigger automation for new Leads/Contacts */
	onlyForNewLeads?: boolean;
}

export enum NewClientType {
	Any = 'Any',
	Commercial = 'Commercial',
	Personal = 'Personal',
	Unknown = 'Unknown',
}

/** Trigger automation when a new client is imported from the integration */
export interface INewClientAutomationTrigger extends IAutomationTrigger {
	clientType?: NewClientType[];
}

export interface IAutomationTemplateReference<
	TDraftTrigger extends IAutomationTrigger = IAutomationTrigger,
	TPublishedTrigger extends IAutomationTrigger = IAutomationTrigger,
> extends IAbstractAutomationTemplate {
	draftSteps?: IAutomationStepReference[];
	draftTrigger?: TDraftTrigger;
	publishedSteps?: IAutomationStepReference[];
	publishedTrigger?: TPublishedTrigger;
	publishedVersionId?: string;
	runtimeDays?: number;
}

export interface IAutomationTemplate<
	TDraftTrigger extends IAutomationTrigger = IAutomationTrigger,
	TPublishedTrigger extends IAutomationTrigger = IAutomationTrigger,
> extends IAbstractAutomationTemplate {
	draft?: IAutomationTemplateVersion<TDraftTrigger>;
	published?: IAutomationTemplateVersion<TPublishedTrigger>;
}

export interface IAutomationOnHold {
	template?: IAutomationTemplateReference;
	count?: number;
}

export interface IAutomationsOnHoldWithContext<T = any> {
	automationsOnHold?: IAutomationOnHold[];
	context?: T;
}

export interface IEmailMessageDraft<TFollowUpOptions extends IFollowUpOptions = IEmailMessageFollowUpOptions>
	extends IEmailMessage<TFollowUpOptions> {
	attachments?: IFileAttachment[];
	groupId?: string;
}

export interface IEmailMessageOptions {
	followUpIfNoResponseInDays?: number;
	keepInTouchFrequency?: number;
	noteVisibility?: ResourceVisibility;
	saveAsNote?: boolean;
	signatureTemplateId?: string;
	subject?: string;
	templateReference?: ITemplateReference;
	sendWithCompliance?: boolean;
	sendToSender?: boolean;
}

export interface ISendMessageAutomationStep {
	attachments?: IFileAttachment[];
	content?: IRawRichTextContentState;
	subject?: string;
}

/** TODO: Rename this to IEmailMessage (replace existing def.) when we do the big async email refactor */
export interface IEmail {
	attachments?: IFileAttachment[];
	bcc?: IRecipient[];
	cc?: IRecipient[];
	content?: IRawRichTextContentState;
	options: IEmailMessageOptions;
}

export interface IEmailAutomationStep extends IAutomationStep, IEmail, ISendMessageAutomationStep {
	tagToAddOnClick?: string;
}

export interface ITextingAutomationStep extends IAutomationStep, ISendMessageAutomationStep {}

export interface IActionItemAutomationStep extends IAutomationStep {
	content?: IRawRichTextContentState;
}

export type INoActionAutomationStep = IAutomationStep;

export interface ITagAutomationStep extends IAutomationStep {
	tagName: string;
}

export type IAddTagAutomationStep = ITagAutomationStep;

export type IRemoveTagAutomationStep = ITagAutomationStep;

export enum ProcessStatus {
	Cancelled = 'Cancelled',
	Completed = 'Completed',
	Failed = 'Failed',
	Queued = 'Queued',
	Skipped = 'Skipped',
	Started = 'Started',
}

export interface IAutomationStatus {
	cancelledDate?: string;
	completedDate?: string;
	errorDate?: string; // date
	errorMessage?: string;
	skippedDate?: string;
	startedDate?: string;
	status?: ProcessStatus;
	outcome?: string;
}

export interface IAutomationReport<TAutomationStep extends IAutomationStep = IAutomationStep> {
	automationId?: string;
	automationStep?: TAutomationStep;
	contacts?: IContact[];
	creator?: IUserReference;
	dueDate?: string;
	id?: string;
	name?: string;
	templateId?: string;
	status?: IAutomationStatus;
	stepIndex?: number;
	stepCount?: number;
}

export interface IAutomationStepStatus<
	TStep extends IAutomationStep = IAutomationStep,
	TStatus extends IAutomationStatusReportView = IAutomationStatusReportView,
> extends IBaseApiModel {
	createdObjectId?: string;
	dueDate?: string;
	isFinished?: boolean;
	status?: TStatus;
	step?: TStep;
}

export interface IAutomation<TStep extends IAutomationStep = IAutomationStep> extends IBaseApiModel {
	anchorDate?: string; // Date
	bulkAutomationId?: string;
	contactIds?: string[];
	name?: string;
	status?: IAutomationStatus;
	steps?: IAutomationStepStatus<TStep>[];
	templateId?: string;
	templateVersionId?: string;
}

export interface IUpcomingAutomationStep {
	dueDate?: string; // date string
	stepIndex: number;
	stepStatusId: string;
}

export interface ICommunicationStatusReportView {
	emailClicked?: boolean;
	emailOpened?: boolean;
	emailReplied?: boolean;
	emailSent?: boolean;
	textSent?: boolean;
}

export interface IAutomationStatusReportView extends IAutomationStatus {
	communicationStatus?: ICommunicationStatusReportView;
}

export interface IAutomationStepStatusReportView<TStep extends IAutomationStep = IAutomationStep>
	extends IAutomationStepStatus<TStep, IAutomationStatusReportView> {
	conditionalSteps?: {
		[key in AutomationStepOutcome]: IAutomationStepStatusReportView;
	};
}

export interface IAutomationReportView extends IBaseResourceModel {
	anchorDate?: string; // Date
	bulkAutomationId?: string;
	contacts?: Partial<IContact>[];
	keyFactsCollection?: IKeyFact[];
	name?: string;
	status?: IAutomationStatus;
	steps?: IAutomationStepStatusReportView[];
	templateId?: string;
	templateVersionId?: string;
}

export interface IAutomationReportRequest extends IPagedResultFetchContext {
	endDate: string;
	resourceSelectorId?: ResourceSelectorId;
	startDate: string; // date string
	userId?: string;
}

export interface IRenewalAutomationReportRequest extends IAutomationReportRequest {
	linesOfBusiness?: string[];
}

export interface IInProgressAutomation {
	automationId?: string;
	name?: string;
}

export interface ITaggingGameActivities {
	contactsDeleted?: number;
	contactsKept?: number;
	tagsAdded?: number;
}

export interface ICreateAutomationRequest extends IBulkContactsRequest {
	autoStart?: boolean;
	putOnHold?: boolean;
	sendFromOptions?: ISendFromOptions;
}

export interface IPreviewCreateAutomationResult {
	excludeContacts?: Partial<IProjectedContact>[];
	contactOwners?: Partial<IProjectedContact>[];
	totalContacts?: number;
}

export interface IInterval {
	startMinutes?: number;
	endMinutes?: number;
}

type SendIntervals = {
	mondayInterval?: IInterval;
	tuesdayInterval?: IInterval;
	wednesdayInterval?: IInterval;
	thursdayInterval?: IInterval;
	fridayInterval?: IInterval;
	observeSendIntervals: boolean;
	saturdayInterval?: IInterval;
	sundayInterval?: IInterval;
	weekdaysInterval?: IInterval;
	/**
	 * Set to true if the specific day of week intervals should be used.
	 */
	useDiscreteIntervals: boolean;
};

export interface ISendEmailFeatures extends SendIntervals {
	/**
	 * Maximum number of emails per day for the entire account
	 */
	maxEmailsPerDay: number;
	observeHolidays?: boolean;
	observedHolidays?: HolidayId[];
	domainName?: string;
}

export interface ITestEmailResult {
	creationDate?: string;
	displayName?: string;
	emailAddress?: string;
	firstName?: string;
	lastName?: string;
}

export interface IDayLoad {
	count?: number;
	date?: string;
}

export interface IGroupCampaignData {
	failedCount: number;
	noEmailAddressCount: number;
	openCount: number;
	repliedCount: number;
	sentCount: number;
	status?: EmailSendStatus;
	totalCount: number;
}

export interface ISupportedPluginFeatures {
	saveAsNote?: boolean;
}

export interface IWelcomeEmail {
	url: string;
	account: string;
}

export enum MeetingLocation {
	Phone = 'PhoneMeetingLocation',
	Other = 'OtherMeetingLocation',
	InPerson = 'InPersonMeetingLocation',
	Virtual = 'VirtualMeetingLocation',
}

export enum MeetingType {
	AllowInvitee = 'AllowInviteeMeetingLocationConfig',
	InPerson = 'InPersonMeetingLocationConfig',
	Other = 'OtherMeetingLocationConfig',
	Phone = 'PhoneMeetingLocationConfig',
	Virtual = 'VirtualMeetingLocationConfig',
}

export interface IMeetingDuration {
	days?: number;
	hours?: number;
	minutes?: number;
	seconds?: number;
}

export interface IMeetingLocationConfig {
	_type?: MeetingType;
}

export interface IPhoneMeetingLocationConfig extends IMeetingLocationConfig {
	willCall?: boolean;
}

export interface IOtherMeetingLocationConfig extends IMeetingLocationConfig {
	instructions?: string;
}

export interface IAllowInviteeLocationConfig extends IMeetingLocationConfig {
	locations?: IMeetingLocation[];
}

export interface IInPersonLocationConfig extends IMeetingLocationConfig {
	address?: string;
}

export interface IVirtualLocationConfig extends IMeetingLocationConfig {
	link?: string;
}

export interface IMeetingLocation {
	_type?: MeetingLocation;
}

export interface IPhoneMeetingLocation extends IMeetingLocation {
	phoneNumber?: string;
}

export interface IOtherMeetingLocation extends IMeetingLocation {
	instructions?: string;
}

export interface IInPersonMeetingLocation extends IMeetingLocation {
	address?: string;
}

export interface IVirtualMeetingLocation extends IMeetingLocation {
	link?: string;
}

export interface IMeetingResource {
	userId?: string;
	initialScore?: number;
	dayFactor?: number;
	weekFactor?: number;
	monthFactor?: number;
}

export interface IMeetingResourceRequirements {
	name?: string;
	minimum?: number;
	maximum?: number;
	required?: boolean;
	meetingResource?: string[];
}

export interface IDailyIntervals {
	intervals?: IInterval[];
}

export interface IMeetingConfigResponseBase extends IBaseApiModel {
	duration?: string;
	name?: string;
}

export interface IMeetingConfigDraftResponse extends IMeetingConfigResponseBase {
	addMeetingNameToCalendarEvent?: boolean;
	allowSameDay?: boolean;
	atLeastInterval?: string;
	atMostInterval?: string;
	availability?: IDailyIntervals[];
	bufferAfterInterval?: string;
	bufferBeforeInterval?: string;
	ccEmails?: string[];
	confirmationNote?: IRawRichTextContentState;
	description?: string;
	includeInEmailSignature?: boolean;
	locationConfig?: IMeetingLocationConfig;
	maxPerDay?: number;
	maxPerMonth?: number;
	meetingReminderTemplateId?: string;
	range?: { start: string; end: string };
	requireCompanyOnMainParticipant?: boolean;
	resources?: IMeetingResourceRequirements;
	shortCode?: string;
	showOnHomepage?: boolean;
	slotStartInMinutes?: number[];
	timeZoneId?: string;
	url?: string;
	vanityPath?: string;
	commentsRequired?: boolean;
	commentsLabel?: string;
	contactFormFields?: ICustomFieldMetaData[];
}

export interface ICustomFieldMetaData {
	fieldName?: string;
	fieldLabel?: string;
	isRequired?: boolean;
}

export interface IMeetingConfigDraft extends Omit<IMeetingConfigDraftResponse, 'duration'> {
	duration?: IMeetingDuration;
}

export interface IMeetingConfigResponse extends IBaseResourceModel {
	draft?: IMeetingConfigDraftResponse;
	published?: IMeetingConfigDraftResponse;
}

export interface IMeetingConfig extends IBaseResourceModel {
	draft?: IMeetingConfigDraft;
	published?: IMeetingConfigDraft;
}

export interface IMeetingConfigLanding {
	companyName: string;
	creatorName: string;
	meetingConfigs: IMeetingConfigInfo[];
}

export interface IMeetingConfigInfo {
	shortCode: string;
	vanityPath: string;
	name: string;
	description: string;
	duration: string;
}

export interface ISchedulerDailyIntervals {
	end?: string;
	start?: string;
}

export interface ISchedulerContact extends IBaseApiModel {
	companyName?: string;
	firstName?: string;
	lastName?: string;
	phoneNumber?: string;
	emailAddress?: string;
	comments?: string;
	isOptional?: boolean;
	timeZoneId?: string;
	alternativeTime?: string;
	textOptIn?: boolean;
	customProperties?: IDictionary<string>;
}

export interface IMeetingConfigPriority {
	enabled: boolean;
	id: string;
	priority: number;
}

export interface ISchedulerResponse extends IMeetingConfigResponseBase {
	allowSameDay?: boolean;
	availabilities?: ISchedulerDailyIntervals[][];
	canEditTitle?: boolean;
	company?: string;
	contacts?: ISchedulerContact[];
	hidePriorities?: boolean;
	host?: string;
	location?: IMeetingLocationConfig;
	maximumEndDate?: string;
	maxPerDay?: number;
	meetingConfigs?: IMeetingConfigPriority[];
	minimumStartDate?: string;
	name?: string;
	requireCompanyOnMainParticipant?: boolean;
	showTextOptIn?: boolean;
	startDate?: string;
	title?: string;
	accountType: 'Levitate' | 'Coffee';
	comments?: string;
	commentsLabel?: string;
	commentsRequired?: boolean;
	contactFormFields?: ICustomFieldMetaData[];
}

export interface ITrialExtensionSettings {
	createdDate?: Date;
	durationInDays: DaysToExtendTrial;
}

export enum DaysToExtendTrial {
	Seven = 7,
	Fourteen = 14,
	Thirty = 30,
}

export interface ISubverticalSettings {
	contentSubscriber?: boolean;
	formality?: ContentFormality;
	subverticals?: string[];
}
export interface IComplianceSettings {
	enabled?: boolean;
	waitTime?: string;
}

export enum ContentFormality {
	Formal,
	Lighthearted,
	ToneMix,
}

export interface IRemoteEvent<T = any> {
	id: string;
	target: { handlerId: string };
	value?: T;
	valueType?: string;
}

export enum RemoteEventsConnectionStatus {
	Connected = 'Connected',
	Connecting = 'Connecting',
	Disconnected = 'Disconnected',
	Failed = 'Failed',
}

export type RemoteEventType = 'update' | 'delete' | 'create' | '#';

export enum RemoteResourceDeliveryOptionType {
	ALL_SINCE_LAST_DELIVERY = 'allSinceLastDelivery',
	LAST_EVENT_ONLY = 'lastEventOnly',
}

export interface IRemoteEventsDeliveryOptions {
	delay?: number;
	type: RemoteResourceDeliveryOptionType;
}

export interface ISendTestEmailOptions<TRecipient> {
	includeAttachments?: boolean;
	onCreateDraft?: (draft: IEmailMessageDraft) => IEmailMessageDraft;
	to?: TRecipient;
}

export enum ContactEmailApproximation {
	GroupSend,
	Html,
}

export enum ContentCalendarSuggestionAccountAge {
	Unknown = 'Unknown',
	New = 'New',
	Existing = 'Existing',
}

export interface IContentCalendarSuggestion extends IBaseApiModel {
	accountAges?: ContentCalendarSuggestionAccountAge[];
	archived?: boolean;
	archivedDate?: Date;
	filter?: IContactFilterCriteria;
	industries?: string[];
	minimumDurationInDays?: number;
	schedule?: ISuggestedSendSchedule;
	subverticals?: string[];
	targets?: SocialMediaType[];
	templateReference?: ITemplateReference;
}

export const ContentCalendarSuggestionAll = '[all]';

export interface IContentCalendarSuggestionRequest {
	accountAges: ContentCalendarSuggestionAccountAge[];
	industries: Industry[];
	subverticals?: string[];
}

export interface ISurveyReviewRequestSettings {
	isEnabled: boolean;
	ratings?: number[];
	reviewRequest?: string;
	reviewType?: string;
	reviewUrl?: string;
}

export interface ISurvey extends IBaseResourceModel {
	_type: SurveyTypes;
	anonymousLink?: string;
	archivedDate?: string; // Date
	expirationDate?: string; // Date
	name?: string;
	responsesAllowed?: boolean;
	startDate?: string; // Date
}

export interface ISatisfactionSurveyStatsSubtotal {
	count: number;
	rating: number;
}

export interface ISurveyStats {
	responses?: number;
}

export interface ISatisfactionSurveyStats extends ISurveyStats {
	averageRating?: number;
	responsesFromKnownContacts?: number;
	subtotals?: ISatisfactionSurveyStatsSubtotal[];
	subtotalsForKnownContacts?: ISatisfactionSurveyStatsSubtotal[];
}

export interface ISatisfactionSurvey extends ISurvey {
	_type: 'SatisfactionSurvey';
	companyDisplayName?: string;
	intro?: string;
	reviewRequestSettings?: ISurveyReviewRequestSettings;
	stats?: ISatisfactionSurveyStats;
}

export interface IEventRegistrationSurveyStats extends ISurveyStats {
	responseStats?: IEventRegistrationSurveyResponseStats[];
}

export enum EventRegistrationSurveyResponseStatCategory {
	Total = 'Total',
	KnownContacts = 'KnownContacts',
}

export interface IEventRegistrationSurveyResponseStats {
	attending?: number;
	category: EventRegistrationSurveyResponseStatCategory;
	noResponse?: number;
	notAttending?: number;
	responses: number;
}

export interface IEventRegistrationSurvey extends ISurvey {
	_type: 'EventRegistrationSurvey';
	attendeeOptions?: IAttendeeOptions;
	eventInformation?: IEventInformation;
	stats?: IEventRegistrationSurveyStats;
}

export interface ISurveyResponse extends IBaseApiModel {
	contact?: Partial<IContact>;
	creationDate?: string; // Date
	surveyId?: string;
	isArchived?: boolean;
}

export interface ISatisfactionSurveyResponse extends ISurveyResponse {
	externalReviewClickDate?: string; // date
	feedback?: string;
	rating: number;
}

export interface IEventRegistrationSurveyResponse extends ISurveyResponse {
	isAttending: boolean;
	attendee: IEventRecipient;
	guests?: IEventRecipient[];
	anonymousGuestCount?: number;
}

export interface ISatisfactionSurveySortDecriptor extends ISortDecriptor {
	sortBy: 'creationDate' | 'rating';
}

export interface IEventSurveySortDescriptor extends ISortDecriptor {
	sortBy: 'creationDate' | 'responseType';
}

export interface IEventSurveyResponseTypeDescriptor extends ISortDecriptor {
	responseType: 'yes' | 'no' | null;
}

export interface ISurveyResponseExportRequest {
	filter?: ISurveyResponseFilterRequest;
	status?: string[];
	surveyId: string;
}

export enum SocialMediaType {
	Unknown = 'Unknown',
	Facebook = 'Facebook',
	Instagram = 'Instagram',
	LinkedIn = 'LinkedIn',
}

export interface ISocialMediaConnection {
	encryptedBlobId?: string;
	expiresAt?: string; // Date
	lastModifiedDate?: string; // Date
	postTargetDisplayName?: string;
	postTargetId?: string;
	state?: SocialMediaConnectionState;
	type: SocialMediaType;
	userId?: string;
	userName?: string;
}

export const SupportedSocialMediaTypes = [
	SocialMediaType.Facebook,
	SocialMediaType.Instagram,
	SocialMediaType.LinkedIn,
];

export enum SocialMediaConnectionState {
	Connected = 'Connected',
	Disconnected = 'Disconnected',
	NoTarget = 'NoTarget',
	Unknown = 'Unknown',
}

export interface ISocialMediaPage {
	id: string;
	name: string;
	canPost?: boolean;
}

export interface ISetPostTargetRequest {
	connection: ISocialMediaConnection;
	pageSelection: ISocialMediaPageSelection;
}

export interface ISocialMediaPageCollection {
	accountId: string;
	pages?: ISocialMediaPage[];
	providerType: SocialMediaType;
}

export interface ISocialMediaPageSelection {
	pageId: string;
	pageName: string;
	userId: string;
}

export interface ISocialMediaPostBase {
	actor?: IActor;
	aiReference?: IAIReference;
	anonymousAccessLink?: string;
	approvalRequests?: ICampaignApprovalRequest[];
	attachments?: IFileAttachment[];
	citations?: string;
	content: IRawRichTextContentState;
	dueDate?: string;
	name?: string;
	sendWithCompliance?: boolean;
	summary?: string;
	templateReference?: ITemplateReference;
	designatedTargets?: IPostTarget[];
}

export interface ICreateSocialMediaPostRequest extends ISocialMediaPostBase {
	forUserId?: string;
	impersonationContext?: IImpersonationContext;
	targets?: SocialMediaType[];
	sendWithComplianceEmail?: string;
}

export interface ISocialMediaPost extends ISocialMediaPostBase, IBaseResourceModel {
	actor?: IActor;
	cancelledDate?: string;
	completedDate?: string;
	filterRequest?: IFilterCriteria;
	status?: PostStatus;
	targets?: IPostTarget[];
}

export interface ISocialMediaMetadata {
	comments?: number;
	reactions?: number;
	views?: number;
}

export interface IPostTarget {
	externalAssociation?: IExternalAssociation;
	metadata?: ISocialMediaMetadata;
	provider: SocialMediaType;
	pageId?: string;
	userId?: string;
	state?: IPostTargetState;
}

export interface IPostTargetState {
	attempts?: number;
	lastAttempted?: string;
	lastConnectionState?: ISocialMediaConnection;
	lastErrorMessage?: string;
	status: PostTargetStatus;
}

export interface IExternalAssociation {
	source: string;
	providerId: string;
	timestamp?: string;
}

export enum PostTargetStatus {
	Unknown = 'Unknown',
	Posted = 'Posted',
	Failed = 'Failed',
	Pending = 'Pending',
}

export enum PostStatus {
	Unknown = 'Unknown',
	Draft = 'Draft',
	Scheduled = 'Scheduled',
	Started = 'Started',
	Cancelled = 'Cancelled',
	Succeeded = 'Succeeded',
	PartiallySucceeded = 'PartiallySucceeded',
	Pending = 'Pending',
	Failed = 'Failed',
}

export enum CardStatus {
	Unknown = 'Unknown',
	Draft = 'Draft',
	Queued = 'Queued',
	Completed = 'Completed',
	PartiallySent = 'PartiallySent',
	Cancelled = 'Cancelled',
	Invalid = 'Invalid',
	Failed = 'Failed',
}

export enum ReportPostStatus {
	All = 'All Posts',
	Cancelled = 'Cancelled',
	Failed = 'Failed',
	Posted = 'Posted',
	Pending = 'Pending',
	Scheduled = 'Scheduled',
}

export enum ReportUser {
	All = 'all',
	Current = 'currentuser',
	User = 'user',
}

export interface ISocialMediaPostReport {
	actor?: IActor;
	anonymousAccessLink?: string;
	approvalRequests?: ICampaignApprovalRequest[];
	name: string;
	status: PostStatus;
	targets: IPostTarget[];
	designatedTargets?: IPostTarget[];
	dueDate: string;
	completedDate: string;
	cancelledDate: string;
	creator: IUser;
	creationDate: string;
	lastModifiedDate?: string;
	id?: string;
	_type: 'PostReportView';
}

export interface ISocialMediaReportRequest {
	endDate?: Date;
	filter?: IFilterCriteria;
	startDate?: Date;
	userId?: string;
}

export enum InvitationStatus {
	Unknown = 'Unknown',
	Pending = 'Pending',
	RequestedRescheduling = 'RequestedRescheduling',
	Confirmed = 'Confirmed',
	Declined = 'Declined',
}

export interface IParticipant extends IBaseApiTypeModel {
	/// If we have a "calendar provider" meeting id
	providerMeetingId?: string;
	status?: InvitationStatus;
	/// Code used to derive the URL for this meeting/this participant
	/// most likely another UUID
	/// Will be used as a pre-authenticated url for the participant to have access to this meeting
	isOptional?: boolean;
	meetingCode?: string;
	timeZoneId?: string;
	user?: IUser;
}

export interface IContactParticipant extends IParticipant {
	contactId?: string;
	emailAddress?: string;
	firstName?: string;
	lastName?: string;
	phoneNumber?: string;
}

export enum MeetingStatus {
	Unknown = 'Unknown',
	Draft = 'Draft',
	Scheduled = 'Scheduled',
	Cancelled = 'Cancelled',
	WaitingForDisposition = 'WaitingForDisposition',
	Completed = 'Completed',
}

export interface IScheduledMeeting extends IBaseResourceModel {
	cancelledDate?: string;
	completedDate?: string;
	confirmationAttempts?: number;
	confirmedDate?: string;
	endDate?: string;
	lastConfirmationAttemptDate?: string;
	participants?: (IParticipant | IContactParticipant)[];
	scheduledDate?: string;
	startDate?: string;
	status?: MeetingStatus;
	title?: string;
}

export enum AutomationSelectionType {
	Myself = 'Myself',
	ContactOwners = 'Contact Owners',
	Employee = 'Employee',
}

export interface IMergePublicToken {
	integration: string;
	integrationProvider: IntegrationSources;
	publicToken: string;
}
export interface IMediaConvertPresignedResult {
	presignedUrl: string;
	jobId: string;
}
export interface IPresignedUrlRequest {
	fileName: string;
	contentType: string;
	contentLength: number;
	duration: number;
	width: number;
	height: number;
}

export interface IMediaConvertSystemJob extends ISystemJobBase {
	success: boolean;
	fileName: string;
	sourceFileType: IFileStorageResourceType;
	mediaConvertJobId: string;
	attachmentId: string;
	targetFileType: IFileStorageResourceType;
	targetFileName: string;
	duration: number;
	sourceWidth: number;
	sourceHeight: number;
}

export interface IBlogGenerationSystemJob extends ISystemJobBase {
	content?: IRawRichTextContentState;
	creativity?: number;
	generatedContent?: IAIGeneratedContent<IBlogPost>;
}

export enum GoogleBusinessConnectionState {
	Unknown = 'Unknown', // only if not explicitly set yet
	Connected = 'Connected',
	Disconnected = 'Disconnected',
}

export interface IGoogleBusinessConnectResponse {
	authUrl: string;
}

export enum IGoogleBusinessAccountType {
	Personal = 'PERSONAL',
	Business = 'BUSINESS',
}

export interface IGoogleBusinessAccount {
	accountName: string;
	name: string;
	type: IGoogleBusinessAccountType;
	sync?: boolean;
}

export interface IGoogleBusinessLocation {
	accountName: string;
	name: string;
	rating: number;
	reviewCount: number;
	title: string;
	sync?: boolean;
}

export interface IGoogleBusinessConnection {
	googleBusinessAccounts: IGoogleBusinessAccount[];
	initialConnectionDate: string;
	locations: IGoogleBusinessLocation[];
	state: GoogleBusinessConnectionState;
	refreshRetryCount: number;
}

export interface IGoogleBusinessMetadata {
	averageRating: number;
	connectionState: GoogleBusinessConnectionState;
	percentReviewsIncreased: number;
	totalReviews: number;
}

export interface IGoogleBusinessReview {
	author: string;
	id: string;
	createTime: Date;
	locationDescription: string;
	path: string;
	rating: string;
	reply: string;
	replyTime: Date;
	reviewer: IGoogleReviewer;
	text: string;
	updateTime: Date;
}

export interface IGoogleBusinessReviewResponse {
	id: string;
	comment: string;
	sessionId?: string;
}

export interface IGoogleBusinessReviewsResourceSelector {
	total: number;
	type: string;
	averageRating: number;
	recentReviews: { values: IGoogleBusinessReview[] };
	allRecentReviews: { values: IGoogleBusinessReview[] };
	percentReviewsIncreased: number;
}

export interface IGoogleReviewer {
	profilePhotoUrl: string;
	name: string;
}

export interface IConnectionType {
	label: string;
	value: string;
}

export enum FormFieldType {
	Boolean = 'Boolean',
	/** Autocomplete field to search for contact */
	Contact = 'Contact',
	Custom = 'Custom',
	/** Date picker */
	Date = 'Date',
	/** Date picker and time picker together */
	DateTime = 'DateTime',
	Decimal = 'Decimal',
	Email = 'Email',
	/** Requires full address, can be multiple fields but requires street, city, state, and zip */
	FullAddress = 'FullAddress',
	Integer = 'Integer',
	Money = 'Money',
	/** Dropdown that supports multiselect */
	MultipleOptions = 'MultipleOptions',
	/** Note editor */
	Note = 'Note',
	/** Dropdown that only supports a single option to be selected */
	Option = 'Option',
	/** Text input */
	String = 'String',
	/** Textarea */
	Text = 'Text',
	/** Time picker only */
	Time = 'Time',
	/** Autocomplete field to search for user */
	User = 'User',
	/** Custom field for COFFEE that allows demo booking to open in a separate window */
	MeetingScheduler = 'MeetingScheduler',
}

export interface IFormField<T> extends IBaseApiModel {
	decimalPlaces?: number;
	fieldType: FormFieldType;
	value?: T;
	isOptional?: boolean;
	isHidden?: boolean;
	label: string;
	maxLength?: number;
	name: string;
	placeHolder?: string;
	mergeFieldFallback?: string;
}

export interface IFormResponse<T = any> extends IFormBase {
	fields: IFormField<T>[];
}

export interface IFormBase extends IBaseApiModel {
	accountId: string;
	creationDate: string;
	creatorId: string;
	id?: string;
	description?: string;
	name?: string;
}

export enum PublicAPIConsumer {
	Unknown = 'Unknown',
	General = 'General',
	Zapier = 'Zapier',
}

export interface IAPIKeyReference {
	blobId: string;
	consumer: PublicAPIConsumer;
	creationDate?: Date;
	creatorId: string;
	description: string;
	lastUsedDate?: Date;
}

export enum TemplateSection {
	Email = 'Email',
	SocialMediaPost = 'SocialMediaPost',
	HandwrittenCard = 'HandwrittenCard',
	Blog = 'Blog',
}

export interface IDonationCampaign extends IBaseResourceModel {
	name: string;
	foregroundColor?: string;
}

export interface IDonation extends IBaseResourceModel {
	donorId: string;
	donorName: string;
	amount: number;
	date: string;
	type: string;
	contactId: string;
	contact: IProjectedContact;
	source: string;
	campaign: IDonationCampaign;
}

export interface IDonor extends IBaseResourceModel {
	contactId: string;
	contact: IProjectedContact;
	donorName: string;
	mostRecentDonation: IDonation;
	lifetimeDonations: number;
	donationCount: number;
}

export interface IImportDonationFieldConfig {
	aliases?: string[];
	name?: string;
	propertyName?: string;
	allowMultiple?: boolean;
	required?: boolean;
}

export interface IImportDonationPreview {
	fields: IImportDonationFieldConfig[];
	header: string[];
	id: string;
	sample: string[][];
}

export enum ImportDonationsContentType {
	csv = 'text/csv',
	xls = 'application/vnd.ms-excel',
	xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export interface IImportDonationsOptions {
	contentType?: ImportDonationsContentType;
	fieldMapping: IDictionary<string>;
}

export interface IImportDonationsJob extends ISystemJob {
	importDonationsOptions: IImportDonationsOptions;
}

export interface IDonationSummary {
	totalDonations: number;
	totalAmount: number;
	totalDonors: number;
}

export interface IDonationExportRequest {
	donationFilter?: IDonationFilterRequest;
	donorFilter?: IDonorFilterRequest;
}

export enum EnableAbility {
	CanEnable = 'CanEnable',
	CannotEnable = 'CannotEnable',
	CleanUpRequired = 'CleanUpRequired',
}

export interface ISaveApiKeyRequest {
	apiKey: string;
}

export interface IInsurancePolicyLineOfBusiness extends IBaseResourceModel {
	name: string;
	foregroundColor?: string;
}

export interface IInsurancePolicyCarrier extends IBaseResourceModel {
	name: string;
}

export interface IInsurancePolicy extends IBaseResourceModel {
	contactId: string;
	carrierId: string;
	typeId: string;
	contact: IProjectedContact;
	policyNumber: string;
	lineOfBusiness: IInsurancePolicyLineOfBusiness;
	renewalDate: string;
	effectiveDate: string;
	premium: number;
	carrier: IInsurancePolicyCarrier;
	isArchived: boolean;
	archiveDate?: Date;
	cancellationDate?: Date;
	originationDate: string;
	ownerId: string;
	owner: IUserReference;
}

export interface IInsurancePolicySummary {
	totalPolicies: number;
	totalAmount: number;
}
